import {
	useSetTrackerTypeSourceMutation,
	useValidicUserPlatformsQuery,
} from '@mobe/api/track/trackApiHooks';
import {
	ITrackerTypeSource,
	TrackerAbbreviation,
	TrackerSource,
} from '@mobe/api/track/trackService';
import BoxButton from '@mobe/components/boxButton/BoxButton';
import { Button } from '@mobe/components/button';
import { InputText } from '@mobe/components/input';
import ButtonRow from '@mobe/components/layout/ButtonRow';
import VrArray from '@mobe/components/layout/VrArray';
import Popup from '@mobe/components/popup/Popup';
import { Text } from '@mobe/components/text';
import TextButton from '@mobe/components/textButton/TextButton';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import useGenericErrorAlert from '@mobe/utils/useGenericErrorAlert';
import { noop } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, ImageSourcePropType, StyleSheet, View } from 'react-native';
import useNativeTrackers from '../../connectHealthData/useNativeTrackers';
import ConnectHealthData from './ConnectHealthDataModule';

const SOURCE_NONE_ID = 0;

interface IConnectedSourcesInputProps {
	sources: ITrackerTypeSource[];
	trackerTypeId: number;
	trackerTitle: string;
	trackerAbbreviation: TrackerAbbreviation;
	hasNewSource?: boolean;
}

export default function ConnectedSourcesInput({
	sources,
	trackerTypeId,
	trackerTitle,
	trackerAbbreviation,
	hasNewSource = false,
}: IConnectedSourcesInputProps) {
	const { t } = useTranslation();
	const genericErrorAlert = useGenericErrorAlert();
	const { vr, vrTop } = useStyleHelpers();
	const nativeTrackers = useNativeTrackers();
	const setTrackerTypeSourceMutation = useSetTrackerTypeSourceMutation();
	const validicUserPlatforms = useValidicUserPlatformsQuery().data || [];

	// Check against Validic platform query to filter out any unconnected sources
	// We must do this because a source will show if it's ever sent data regardless of connection status
	const connectedPlatformNames = validicUserPlatforms
		.filter((platform) => platform.isConnected)
		.map((platform) => platform.trackerSourceName);
	const nativeTrackerNames = nativeTrackers.map((platform) => platform.trackerSourceName);
	const disconnectedNativeTrackerNames = nativeTrackers
		.filter((platform) => !platform.isConnected)
		.map((platform) => platform.trackerSourceName);
	const connectedSources = sources.filter((source) =>
		[...connectedPlatformNames, ...nativeTrackerNames].includes(source.trackerSourceName)
	);
	const primarySource = connectedSources.find((source) => source.isPrimary);
	const [selectedSourceId, setSelectedSourceId] = React.useState(
		primarySource?.trackerSourceId || SOURCE_NONE_ID
	);
	const showNewSourceAlert = hasNewSource && connectedSources.length > 1;
	const [popupIsOpen, setPopupIsOpen] = React.useState(false);

	// Automatically open popup on component instantiation if hasNewSource
	React.useEffect(() => {
		// Also ensure that there are more than 1 connected sources before showing the popup
		if (showNewSourceAlert) {
			setTimeout(() => setPopupIsOpen(true), 500);
		}
	}, [showNewSourceAlert]);

	// Reset selection if popup is closed or if sources change
	React.useEffect(() => {
		if (primarySource?.trackerSourceId !== selectedSourceId || !popupIsOpen) {
			setSelectedSourceId(primarySource?.trackerSourceId || SOURCE_NONE_ID);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [primarySource, popupIsOpen]);

	function getPlatformData(sourceName: TrackerSource): {
		displayName?: string;
		logoSource?: ImageSourcePropType;
	} {
		const nativeTracker = nativeTrackers.find(
			(tracker) => tracker.trackerSourceName === sourceName
		);

		if (nativeTracker) {
			return {
				displayName: nativeTracker.displayName,
				logoSource: nativeTracker.logoSource,
			};
		}

		const platform = validicUserPlatforms.find(
			(platform) => platform.trackerSourceName === sourceName
		);

		return {
			displayName: platform?.displayName,
			logoSource: platform?.logoSource,
		};
	}

	function handleUpdateOpenPress() {
		setPopupIsOpen(true);
	}

	function handleClosePress() {
		if (showNewSourceAlert) {
			return;
		}

		setPopupIsOpen(false);
	}

	function handleSavePress() {
		setTrackerTypeSourceMutation
			.mutateAsync({ trackerTypeId, trackerSourceId: selectedSourceId })
			.then(() => setPopupIsOpen(false))
			.catch(handleError);
	}

	function handleOnSelect(id: number) {
		setSelectedSourceId(id !== selectedSourceId ? id : SOURCE_NONE_ID);
	}

	function handleError() {
		genericErrorAlert(() => setPopupIsOpen(false));
	}

	return (
		<>
			<InputText
				label={t('track.connectedHealthData.input.label')}
				disabled
				collapseEmptyError
				inputLeftContainerStyle={{ padding: 4, marginLeft: 0, alignSelf: 'stretch' }}
				inputLeft={() =>
					primarySource ? (
						<ConnectedSource
							sourceId={primarySource.trackerSourceId}
							{...getPlatformData(primarySource.trackerSourceName)}
						/>
					) : (
						<ConnectedSource
							sourceId={SOURCE_NONE_ID}
							displayName={t('track.connectedHealthData.input.noConnectedSourceValue')}
						/>
					)
				}
				inputRight={() => (
					<TextButton
						title={t('track.detail.goalModuleUpdateButton')}
						onPress={handleUpdateOpenPress}
					/>
				)}
			/>
			{popupIsOpen && (
				<Popup
					heading={
						showNewSourceAlert
							? t('track.connectedHealthData.selectSource.title')
							: t('track.connectedHealthData.updateSource.title')
					}
					showClose={!showNewSourceAlert}
					onClosePress={handleClosePress}
					footer={
						<ButtonRow>
							{!showNewSourceAlert && (
								<Button
									title={t('track.connectedHealthData.updateSource.cancelButton')}
									variant="secondary"
									onPress={handleClosePress}
									disabled={setTrackerTypeSourceMutation.isPending}
								/>
							)}
							<Button
								title={
									showNewSourceAlert
										? t('track.connectedHealthData.updateSource.saveButton')
										: t('track.connectedHealthData.updateSource.saveButton')
								}
								onPress={handleSavePress}
								loading={setTrackerTypeSourceMutation.isPending}
							/>
						</ButtonRow>
					}
				>
					<Text weight="medium" size="lg" align="center" style={[vrTop(2), vr(6)]}>
						{showNewSourceAlert
							? t('track.connectedHealthData.selectSource.body', { trackerTitle })
							: t('track.connectedHealthData.updateSource.body', { trackerTitle })}
					</Text>
					<VrArray style={vr(8)}>
						{connectedSources.map((source) => (
							<ConnectedSource
								key={source.trackerSourceId}
								sourceId={source.trackerSourceId}
								variant="button"
								isSelected={source.trackerSourceId === selectedSourceId}
								isDisabled={disconnectedNativeTrackerNames.includes(source.trackerSourceName)}
								onSelect={handleOnSelect}
								{...getPlatformData(source.trackerSourceName)}
							/>
						))}
					</VrArray>
					{!showNewSourceAlert && (
						<View style={vr(3)}>
							<ConnectHealthData variant="manage" trackerAbbreviation={trackerAbbreviation} />
						</View>
					)}
				</Popup>
			)}
		</>
	);
}

function ConnectedSource({
	displayName,
	logoSource,
	sourceId,
	variant = 'readOnly',
	isSelected = false,
	isDisabled = false,
	onSelect,
}: {
	displayName?: string;
	logoSource?: ImageSourcePropType;
	sourceId: number;
	variant?: 'readOnly' | 'button';
	isSelected?: boolean;
	isDisabled?: boolean;
	onSelect?: (id: number) => void;
}) {
	const rules = useStyleRules();

	const styles = StyleSheet.create({
		container: {
			flexDirection: 'row',
			alignItems: 'center',
			height: '100%',
			paddingHorizontal: 10,
			borderWidth: StyleSheet.hairlineWidth,
			borderColor: rules.colors.stroke,
			borderRadius: rules.borderRadius,
			backgroundColor: isDisabled ? rules.colors.strokeLight : rules.colors.cardBackground,

			...(variant === 'button' && {
				height: undefined,
				minHeight: 40,
				borderWidth: 0,
			}),
		},
		image: {
			width: 50,
			height: '100%',
			backgroundColor: rules.colors.staticLight,
			borderRadius: rules.borderRadius,
		},
		divider: {
			height: '50%',
			marginHorizontal: 10,
			borderLeftWidth: StyleSheet.hairlineWidth,
			borderColor: rules.colors.stroke,
		},
	});

	function renderBody() {
		return (
			<>
				{logoSource && (
					<>
						<Image source={logoSource} style={styles.image} resizeMode="contain" />
						<View style={styles.divider} />
					</>
				)}
				<Text
					color={variant === 'button' ? 'regular' : 'light'}
					weight="medium"
					size={variant === 'button' ? 'md' : 'sm'}
				>
					{displayName}
				</Text>
			</>
		);
	}

	if (variant === 'button') {
		return (
			<BoxButton
				role="radio"
				selected={isSelected}
				disabled={isDisabled}
				onPress={() => (onSelect !== undefined ? onSelect(sourceId) : noop())}
			>
				<View style={styles.container}>{renderBody()}</View>
			</BoxButton>
		);
	}

	return <View style={styles.container}>{renderBody()}</View>;
}

import { useAccessibilityFocus } from '@mobe/utils/a11y';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import { useAccessibilityInfo } from '@mobe/utils/useAccessibilityInfo';
import MultiSlider from '@ptomasroos/react-native-multi-slider';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, Pressable, StyleSheet, View } from 'react-native';
import Text from '../text/Text';

const SLIDER_LENGTH = 300;

export interface IInputSliderProps {
	label?: string;
	labelLeft?: string;
	labelRight?: string;
	minimumValue?: number;
	maximumValue?: number;
	value: number;
	showTooltipBeforeInteraction?: boolean;
	onValueChange: (value: number) => void;
}

export default function InputSlider({
	label,
	labelLeft,
	labelRight,
	minimumValue = 0,
	maximumValue = 10,
	value,
	showTooltipBeforeInteraction = false,
	onValueChange,
}: IInputSliderProps) {
	const { constrainText } = useStyleHelpers();
	const { t } = useTranslation();
	const [showTooltip, setShowTooltip] = React.useState(showTooltipBeforeInteraction);
	const [markerRef, setFocusToMarker] = useAccessibilityFocus(500);
	const accessibilityInfo = useAccessibilityInfo();
	const rangeNumbers = Array(Math.abs(maximumValue - minimumValue) + 1)
		.fill(minimumValue)
		.map((number, i) => number + i);
	const styles = useStyles(rangeNumbers.length);

	function increment() {
		if (value === maximumValue) return;
		setShowTooltip(true);
		onValueChange(value + 1);
	}

	function decrement() {
		if (value === minimumValue) return;
		setShowTooltip(true);
		onValueChange(value - 1);
	}

	function handleTrackPress(value: number) {
		setShowTooltip(true);
		onValueChange(value);
	}

	const markerWebProps =
		Platform.OS === 'web'
			? {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					onKeyDown: (event) => {
						switch (event.key) {
							case 'ArrowRight':
								increment();
								break;
							case 'ArrowLeft':
								decrement();
								break;
						}
					},
			  }
			: undefined;

	React.useEffect(() => {
		// VO doesn't seem to follow the marker very well, trying to move focus when value changes
		if (Platform.OS === 'ios' || Platform.OS === 'web') {
			setFocusToMarker();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	return (
		<>
			{label !== undefined && (
				<Text weight="semiBold" size="xl" align="center" style={constrainText(320)}>
					{label}
				</Text>
			)}
			<MultiSlider
				values={[value]}
				onValuesChangeFinish={(values) => {
					onValueChange(values[0]);
				}}
				onValuesChange={(values) => onValueChange(values[0])}
				onValuesChangeStart={() => setShowTooltip(true)}
				min={minimumValue}
				max={maximumValue}
				snapped
				step={1}
				allowOverlap={true}
				sliderLength={SLIDER_LENGTH}
				trackStyle={styles.track}
				selectedStyle={styles.trackSelected}
				unselectedStyle={styles.trackUnselected}
				containerStyle={styles.container}
				customMarker={({ currentValue }) => (
					<View
						ref={markerRef}
						accessible
						focusable
						role="slider"
						accessibilityActions={[
							// Labels didn't work for a11y
							{ name: 'increment' },
							{ name: 'decrement' },
						]}
						onAccessibilityAction={(event) => {
							switch (event.nativeEvent.actionName) {
								case 'increment':
									increment();
									break;
								case 'decrement':
									decrement();
									break;
							}
						}}
						aria-valuetext={t('forms.rangeSlider.currentValueDescription', {
							count: currentValue,
							max: maximumValue,
						})}
						aria-valuenow={currentValue}
						aria-valuemin={minimumValue}
						aria-valuemax={maximumValue}
						style={styles.markerContainer}
						{...markerWebProps}
					>
						<View style={styles.marker}>
							<View style={styles.markerDot} />
							{/* This made iOS stop double reading the current value, accessibilityHiddenElement was not working either */}
							{!accessibilityInfo.screenReaderEnabled && showTooltip ? (
								<View style={styles.markerTooltip}>
									<Text style={styles.markerTooltipText} weight="bold">
										{currentValue}
									</Text>
								</View>
							) : null}
						</View>
					</View>
				)}
			/>
			<View style={styles.notchContainer}>
				{rangeNumbers.map((number) => (
					<View key={number} style={styles.notch}>
						{number !== value && (
							// Invisible array of Pressable elements that span the length of the track.
							// Allows for tapping the track in addition to dragging the marker.
							<Pressable
								key={number}
								style={styles.tapSelection}
								onPress={() => handleTrackPress(number)}
								accessibilityElementsHidden={true}
								importantForAccessibility="no-hide-descendants"
								focusable={false}
								{...{ tabIndex: -1 }} // TODO: When RN fixes types for tabIndex please add the prop vs this spread workaround
							></Pressable>
						)}
					</View>
				))}
			</View>
			<View style={styles.rangeLabelContainer}>
				<View accessible style={styles.rangeLabelContainerLeft}>
					<View style={styles.rangeLabelContainerValue}>
						<Text color="light" weight="semiBold" align="center">
							{minimumValue}
						</Text>
					</View>
					<Text color="light" align="left" style={constrainText(130)}>
						{labelLeft}
					</Text>
				</View>
				<View accessible style={styles.rangeLabelContainerRight}>
					<View style={styles.rangeLabelContainerValue}>
						<Text color="light" weight="semiBold" align="center">
							{maximumValue}
						</Text>
					</View>
					<Text color="light" align="right" style={constrainText(130)}>
						{labelRight}
					</Text>
				</View>
			</View>
		</>
	);
}

function useStyles(rangeCount: number) {
	const styleRules = useStyleRules();
	/**
	 * Width of slider marker and pressable segments.
	 * Accounts for 50% item overflow off left and right ends of track.
	 */
	const itemWidth = (SLIDER_LENGTH + SLIDER_LENGTH / rangeCount) / rangeCount;

	return StyleSheet.create({
		container: {
			alignItems: 'center',
			height: 30,
			marginTop: 60,
		},
		markerContainer: {
			alignItems: 'center',
			justifyContent: 'center',
			height: 50,
			width: 50,
		},
		marker: {
			alignItems: 'center',
			justifyContent: 'center',
			width: itemWidth,
			height: itemWidth,
			backgroundColor: 'white',
			borderRadius: 15,
			borderColor: styleRules.colors.stroke,
			borderWidth: 1,
		},
		markerDot: {
			height: 6,
			width: 6,
			backgroundColor: styleRules.colors.primary,
			borderRadius: 3,
		},
		markerTooltip: {
			position: 'absolute',
			top: -40,
			minWidth: 50,
			justifyContent: 'center',
			alignItems: 'center',
		},
		markerTooltipText: {
			fontSize: 38,
			lineHeight: 38,
		},
		track: {
			height: 20,
			borderRadius: 10,
			transform: [{ translateY: -10 }],
		},
		trackSelected: {
			backgroundColor: styleRules.colors.primary,
		},
		trackUnselected: {
			backgroundColor: styleRules.colors.strokeLight,
		},
		notchContainer: {
			flexDirection: 'row',
			width: SLIDER_LENGTH,
			alignSelf: 'center',
			justifyContent: 'space-between',
			marginBottom: 8,
		},
		notch: {
			width: 1,
			height: 10,
			backgroundColor: styleRules.colors.stroke,
		},
		tapSelection: {
			height: itemWidth,
			width: itemWidth,
			position: 'absolute',
			top: -24 - itemWidth / 2,
			left: itemWidth / -2,
		},
		rangeLabelContainer: {
			flexDirection: 'row',
			width: SLIDER_LENGTH,
			alignSelf: 'center',
			justifyContent: 'space-between',
		},
		rangeLabelContainerValue: { minWidth: itemWidth },
		rangeLabelContainerLeft: {
			alignItems: 'flex-start',
			marginLeft: -itemWidth / 2,
			maxWidth: SLIDER_LENGTH / 2,
		},
		rangeLabelContainerRight: {
			alignItems: 'flex-end',
			marginRight: -itemWidth / 2,
			maxWidth: SLIDER_LENGTH / 2,
		},
	});
}

import { TrackerAbbreviation } from '@mobe/api/track/trackService';
import Dismissible from '@mobe/components/dismissible/Dismissible';
import { SmartWatchGraphic } from '@mobe/components/graphics';
import Icon from '@mobe/components/icon/Icon';
import IconButton from '@mobe/components/iconButton/IconButton';
import { Text } from '@mobe/components/text';
import { useHasDismissedTrackerCTAFromTrack } from '@mobe/utils/storage/appStateStorage';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import { CommonActions, useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { TrackDetailScreenNavigationProp } from '../../navigation/modal/types';
import * as TrackAnalyticsEvents from '../analyticsEvents';

type Variant = 'connect' | 'manage';

export default function ConnectHealthDataModule({
	variant = 'connect',
	style,
	trackerAbbreviation,
}: {
	variant?: Variant;
	style?: StyleProp<ViewStyle>;
	trackerAbbreviation: TrackerAbbreviation;
}) {
	const navigation = useNavigation<TrackDetailScreenNavigationProp>();
	const [, setHasDismissedTrackerCTAFromTrack] = useHasDismissedTrackerCTAFromTrack();
	const { t } = useTranslation();
	const styles = useStyles(variant);

	function handleDismiss() {
		TrackAnalyticsEvents.connectHealthCtaDismiss(trackerAbbreviation);
		setHasDismissedTrackerCTAFromTrack(true);
	}

	return (
		<Dismissible onAnimationComplete={handleDismiss}>
			{(dismiss) => (
				<View style={style}>
					<Pressable
						style={styles.box}
						onPress={() => {
							variant === 'connect'
								? TrackAnalyticsEvents.connectHealthCtaPress(trackerAbbreviation)
								: TrackAnalyticsEvents.connectHealthDataManage(trackerAbbreviation);
							navigation.dispatch(CommonActions.navigate('CONNECT_HEALTH_DATA_SCREEN'));
						}}
					>
						<View style={styles.graphic}>
							<SmartWatchGraphic />
						</View>
						<View style={styles.body}>
							<Text weight="medium">
								{variant === 'connect'
									? t('track.connectedHealthData.connectCTA.title')
									: t('track.connectedHealthData.manageCTA.title')}
							</Text>
							<Text weight="semiBold" color="primary">
								{variant === 'connect'
									? t('track.connectedHealthData.connectCTA.button')
									: t('track.connectedHealthData.manageCTA.button')}
							</Text>
						</View>
						<Icon name="right" size={15} />
					</Pressable>
					{variant === 'connect' && (
						<View style={styles.closeButton}>
							<IconButton name="close" onPress={dismiss} size={10} hitSlop={15} />
						</View>
					)}
				</View>
			)}
		</Dismissible>
	);
}

function useStyles(variant: Variant) {
	const rules = useStyleRules();

	return StyleSheet.create({
		box: {
			width: '100%',
			backgroundColor: rules.colors.cardBackground,
			padding: 12,
			flexDirection: 'row',
			alignItems: 'center',
			columnGap: 10,
			borderWidth: 1,
			borderColor: rules.colors.strokeLight,
			borderRadius: rules.borderRadius,

			...(variant === 'connect' && {
				paddingLeft: 30,
			}),
		},
		body: {
			flexGrow: 1,
			flexShrink: 1,
			justifyContent: 'center',
		},
		graphic: {
			flexShrink: 0,
			maxWidth: 28,
		},
		closeButton: {
			position: 'absolute',
			top: 10,
			left: 10,
		},
	});
}

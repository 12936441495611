import { yupResolver } from '@hookform/resolvers/yup';
import { useTrackersQuery } from '@mobe/api/track/trackApiHooks';
import { TrackerAbbreviation } from '@mobe/api/track/trackService';
import {
	InputText,
	useControlledCalendarInputProps,
	useControlledInputProps,
} from '@mobe/components/input';
import InputDateTime from '@mobe/components/input/InputDateTime';
import InputSelect from '@mobe/components/input/InputSelect';
import { Text } from '@mobe/components/text';
import { emptyStringToNull } from '@mobe/utils/i18n';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import * as React from 'react';
import { useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import * as Yup from 'yup';
import { ITrackerForm, IUseTrackerForm } from '../../useTrackerContent';

export default function useBloodGlucoseForm({
	tracker,
	trackerEntry,
	isRequired = true,
}: IUseTrackerForm): ITrackerForm {
	const { t } = useTranslation();
	const { vr } = useStyleHelpers();
	const trackersQuery = useTrackersQuery();
	const units =
		trackersQuery.data?.find(
			(tracker) => tracker.trackerAbbreviation === TrackerAbbreviation.BloodGlucose
		)?.units || [];

	const trackerSchema = Yup.number()
		.label(tracker.inputLabel)
		.nullable()
		.transform(emptyStringToNull)
		.typeError(t('forms.errors.invalidType'));

	const validationSchema = Yup.object({
		date: Yup.date().required(),
		tracker: isRequired ? trackerSchema.required() : trackerSchema,
		unit: Yup.number(),
	});

	const form = useForm<Yup.InferType<typeof validationSchema>>({
		mode: 'all',
		resolver: yupResolver(validationSchema),
		defaultValues: {
			date: trackerEntry ? new Date(trackerEntry.activityDateTime) : new Date(Date.now()),
			tracker: trackerEntry ? trackerEntry.value : null,
			unit: trackerEntry ? trackerEntry.unitId : units.length > 0 ? units[0].id : 0,
		},
	});

	const dateInputProps = useControlledCalendarInputProps({
		name: 'date',
		control: form.control,
	});

	const trackerInputProps = useControlledInputProps({
		name: 'tracker',
		control: form.control,
	});

	const unitInputProps = useController({
		name: 'unit',
		control: form.control,
	});

	function renderForm() {
		return (
			<>
				<View style={vr(2)}>
					<InputDateTime
						label={t('track.entry.dateLabel')}
						popupLabel={t('track.entry.calendarTitle')}
						errorMessage={form.formState.errors.date?.message}
						{...dateInputProps}
					/>
				</View>
				<InputText
					type="integer"
					label={tracker.inputLabel}
					inputRight={() => <Text weight="semiBold">{tracker.unit(0)}</Text>}
					maxLength={3}
					style={vr(2)}
					{...trackerInputProps}
				/>
				<InputSelect
					label={t('trackers.bloodGlucose.measurementDetails')}
					items={units.map((unit) => ({
						label: unit.description,
						value: unit.id,
					}))}
					value={unitInputProps.field.value || 0}
					onSelect={unitInputProps.field.onChange}
				/>
			</>
		);
	}

	return { form, renderForm };
}

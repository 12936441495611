import { yupResolver } from '@hookform/resolvers/yup';
import { useTrackersQuery } from '@mobe/api/track/trackApiHooks';
import { TrackerAbbreviation } from '@mobe/api/track/trackService';
import { useControlledCalendarInputProps } from '@mobe/components/input';
import InputDateTime from '@mobe/components/input/InputDateTime';
import Row from '@mobe/components/layout/Row';
import NativeWheelPicker from '@mobe/components/nativeWheelPicker/NativeWheelPicker';
import { Text } from '@mobe/components/text';
import { emptyStringToNull } from '@mobe/utils/i18n';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { noop } from 'lodash';
import * as React from 'react';
import { useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Platform, View } from 'react-native';
import * as Yup from 'yup';
import { ITrackerForm, IUseTrackerForm } from '../../useTrackerContent';
import { bloodPressureNumberToObject, bloodPressureObjectToNumber } from '../bloodPressureUtils';

const DEFAULT_VALUE = 120.08;

export default function useBloodPressureFormWheelInput({
	tracker,
	trackerEntry,
	isRequired = true,
	onKeyboardSubmit = noop, // only used in .web version of component
}: IUseTrackerForm): ITrackerForm {
	const { t } = useTranslation();
	const { vr, constrainText } = useStyleHelpers();
	const trackersQuery = useTrackersQuery();
	const lastValue = trackersQuery.data?.find(
		(tracker) => tracker.trackerAbbreviation === TrackerAbbreviation.BloodPressure
	)?.lastValue;

	const trackerSchema = Yup.number()
		.label(tracker.inputLabel)
		.transform(emptyStringToNull)
		.typeError(t('forms.errors.invalidType'));

	const validationSchema = Yup.object({
		date: Yup.date().required(),
		tracker: isRequired ? trackerSchema.required() : trackerSchema,
	});

	const form = useForm<Yup.InferType<typeof validationSchema>>({
		mode: 'all',
		resolver: yupResolver(validationSchema),
		defaultValues: {
			date: trackerEntry ? new Date(trackerEntry.activityDateTime) : new Date(Date.now()),
			tracker: trackerEntry?.value ?? lastValue ?? DEFAULT_VALUE,
		},
	});

	const dateInputProps = useControlledCalendarInputProps({
		name: 'date',
		control: form.control,
	});

	const trackerInputProps = useController({ name: 'tracker', control: form.control });

	const { systolic, diastolic } = bloodPressureNumberToObject(
		trackerInputProps.field.value || DEFAULT_VALUE
	);

	function renderForm() {
		return (
			<>
				<View style={vr(5)}>
					<InputDateTime
						label={t('track.entry.dateLabel')}
						popupLabel={t('track.entry.calendarTitle')}
						errorMessage={form.formState.errors.date?.message}
						{...dateInputProps}
					/>
				</View>
				<Row gutterSize={Platform.OS === 'ios' ? 0 : 12} style={constrainText(250)}>
					<Row.Item fill>
						<Text weight="semiBold" align="center">
							Systolic
						</Text>
						<NativeWheelPicker
							range={{ min: 0, max: 250 }}
							selectedValue={systolic}
							onChange={(value) =>
								trackerInputProps.field.onChange(
									bloodPressureObjectToNumber({ systolic: value, diastolic })
								)
							}
						/>
					</Row.Item>
					<Row.Item fill>
						<Text weight="semiBold" align="center">
							Diastolic
						</Text>
						<NativeWheelPicker
							range={{ min: 0, max: 200 }}
							selectedValue={diastolic}
							onChange={(value) =>
								trackerInputProps.field.onChange(
									bloodPressureObjectToNumber({ systolic, diastolic: value })
								)
							}
						/>
					</Row.Item>
				</Row>
			</>
		);
	}

	return { form, renderForm };
}

import Row from '@mobe/components/layout/Row';
import { Text } from '@mobe/components/text';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

export default function BloodPressureLegend() {
	const styleRules = useStyleRules();
	const { t } = useTranslation();

	const styles = StyleSheet.create({
		legend: {
			flexDirection: 'row',
			flexWrap: 'wrap',
			alignSelf: 'flex-start',
			padding: 5,
			paddingHorizontal: 10,
			borderColor: styleRules.colors.stroke,
			borderWidth: StyleSheet.hairlineWidth,
			borderRadius: 50,
			gap: 16,
		},
		legendMarker: {
			width: 10,
			height: 10,
			backgroundColor: 'cadetblue',
			borderRadius: 5,
		},
	});

	return (
		<View style={styles.legend}>
			<Row gutterSize={6}>
				<Row.Item>
					<View style={[styles.legendMarker, { backgroundColor: '#63B3E1' }]}></View>
				</Row.Item>
				<Row.Item>
					<Text size="sm" color="light">
						{t('trackers.BloodPressure.systolic')}
					</Text>
				</Row.Item>
			</Row>
			<Row gutterSize={6}>
				<Row.Item>
					<View style={[styles.legendMarker, { backgroundColor: '#F6C463' }]}></View>
				</Row.Item>
				<Row.Item>
					<Text size="sm" color="light">
						{t('trackers.BloodPressure.diastolic')}
					</Text>
				</Row.Item>
			</Row>
		</View>
	);
}

import {
	ITrackerAggregatedEntryDataItem,
	ITrackerDetail,
	TrackerAbbreviation,
	TrackerAggregatedEntriesInterval,
} from '@mobe/api/track/trackService';
import {
	MoodGraphic,
	TrackerA1cGraphic,
	TrackerBloodGlucoseGraphic,
	TrackerBloodPressureGraphic,
	TrackerEmotionalHealthGraphic,
	TrackerHydrationGraphic,
	TrackerMoodGraphic,
	TrackerMovementGraphic,
	TrackerNutritionGraphic,
	TrackerSleepGraphic,
	TrackerStepsGraphic,
	TrackerStressExperienceGraphic,
	TrackerStressManagementGraphic,
	TrackerWeightGraphic,
} from '@mobe/components/graphics';
import { Text } from '@mobe/components/text';
import { capitalize } from 'lodash';
import * as React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import * as Yup from 'yup';
import { RequiredDateSchema } from 'yup/lib/date';
import { AssertsShape } from 'yup/lib/object';
import { AnyObject } from 'yup/lib/types';
import BloodGlucoseTable from './components/BloodGlucoseTable';
import DefaultTrackerTable, { IDefaultTrackerTableProps } from './components/DefaultTrackerTable';
import MoodTrackerTable from './components/MoodTrackerTable';
import SleepTrackerTable from './components/SleepTrackerTable';
import { bloodPressureNumberToObject } from './components/bloodPressureUtils';
import A1CTable from './components/chart/A1CTable';
import BloodGlucoseChart from './components/chart/BloodGlucoseChart';
import BloodPressureChart from './components/chart/BloodPressureChart';
import MoodChart from './components/chart/MoodChart';
import TrackerBarChart from './components/chart/TrackerBarChart';
import useBloodGlucoseForm from './components/entryForm/useBloodGlucoseForm';
import useBloodPressureForm from './components/entryForm/useBloodPressureForm';
import useIntegerForm from './components/entryForm/useIntegerForm';
import useLikertScaleForm from './components/entryForm/useLikertScaleForm';
import useMoodForm from './components/entryForm/useMoodForm';
import useNumberForm from './components/entryForm/useNumberForm';
import useSliderForm from './components/entryForm/useSliderForm';
import useHeightForm from './useHeightForm';

export enum TrackerInputType {
	NumberInput,
	IntegerInput,
	Slider,
	RadioGraphic,
}

export enum GoalMetRule {
	Above = 'above',
	Below = 'below',
}

interface ITrackerContentTranslations {
	title: string;
	shortDescription: string;
	longDescription: string;
	goalQuestion: string;
	goalUnit: string;
	inputLabel: string;
	aggregateUnit: string;
	chartLabel: string;
	chartLabelDaysCombined: string;

	/**
	 * Maps numeric value to accessibility label for radioGraphics.
	 */
	valueOptions?: Record<number, string>;
}

interface ISecondaryInput {
	/**
	 * Unit for the secondary input, e.g. inches or minutes.
	 */
	unit: string;

	/**
	 * An expression of how many units of the secondary input equate a single unit of the primary input.
	 * This value would be 12 for inches (of a foot), or 60 for minutes (of an hour).
	 */
	conversionFactor: number;
}

export interface IAuxiliaryForm {
	renderForm: (screen: 'goal' | 'addEntry' | 'editEntry') => React.ReactNode;
	isDirty: boolean;
	isValid: boolean;
	handleSubmit: () => void;
}

export interface IUseTrackerForm {
	tracker: ITrackerContent;
	trackerEntry?: ITrackerDetail;
	isGoal?: boolean;
	goal?: number | null;
	customInputLabel?: string;
	staticDate?: Date;
	isRequired?: boolean;
	onKeyboardSubmit?: () => void;
}

export interface ITrackerForm {
	form:
		| UseFormReturn<
				AssertsShape<{
					date: RequiredDateSchema<Date | undefined, AnyObject>;
					tracker: Yup.AnySchema;
					unit?: Yup.AnySchema;
				}>
		  >
		| UseFormReturn<
				AssertsShape<{
					date: RequiredDateSchema<Date | undefined, AnyObject>;
					tracker: Yup.AnySchema;
					unit: Yup.AnySchema;
				}>
		  >;
	renderForm: () => JSX.Element;
}

export interface ITrackerChartProps {
	rawData?: ITrackerAggregatedEntryDataItem[];
	interval?: TrackerAggregatedEntriesInterval;
	chartHeight?: number;
	customYTickLabelComponent?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
	hideLegend?: boolean;
	padding?: { top?: number; bottom?: number; left?: number; right?: number };
}

export interface ITrackerContent extends ITrackerContentTranslations {
	unit: (count: number | undefined) => string;
	lastEntry: (count: number | undefined) => React.ReactNode;
	maxValue?: number;

	/**
	 * This value defines the smallest scale for the tracker value axis in charts.
	 * e.g. a value of 10 means that the y-axis of a bar chart will not be less than 10.
	 */
	minChartScale: number;
	graphic: React.ReactNode;
	canSetGoal: boolean;
	goalMetRule?: GoalMetRule;

	/**
	 * Supplying a goalValue will override the default `${value} ${goalUnit}` with a custom output
	 */
	goalValue?: (count: number | undefined) => string;
	useForm: (props: IUseTrackerForm) => ITrackerForm;
	renderChart: (props?: ITrackerChartProps) => React.ReactNode;
	isStandaloneChart?: boolean;
	renderTable: (props: IDefaultTrackerTableProps) => React.ReactNode;

	/**
	 * SecondaryInput can only be used in conjunction with an inputType of IntegerInput
	 */
	secondaryInput?: ISecondaryInput;
	secondaryUnit?: (count: number | undefined) => string;

	/**
	 * Optional form that displays beneath the add entry form
	 */
	useAuxiliaryForm?: () => IAuxiliaryForm;

	/**
	 * Determines whether or not to display CTA on relevant screens
	 */
	showConnectHealthDataCTA: boolean;
	showMonthHighlight: boolean;
	showHealthKitPermissionsModal: boolean;
	hideViewAllData?: boolean;
	disableScrollOnForm?: boolean;
}

export default function useTrackerContent() {
	const { t } = useTranslation();
	const likertDataRange = 5;
	const sliderDataRange = 10;
	const renderDefaultTable = (props: IDefaultTrackerTableProps) => (
		<DefaultTrackerTable {...props} unit={capitalize(props.unit)} />
	);
	const renderLastEntry = (text: string, accessibilityLabel?: string) => {
		return (
			<Text size="xl" weight="bold" aria-label={accessibilityLabel}>
				{text}
			</Text>
		);
	};

	const trackerContentMap: Record<TrackerAbbreviation, ITrackerContent> = {
		[TrackerAbbreviation.Mood]: {
			...t('trackers.mood', { returnObjects: true }),
			unit: (count = 0) => t('trackers.mood.unit', { count }),
			lastEntry: (count = 3) => (
				<View style={{ paddingTop: 5, width: 25 }}>
					<MoodGraphic expression={count} colored={true} />
				</View>
			),
			minChartScale: 5,
			graphic: <TrackerMoodGraphic />,
			canSetGoal: false,
			useForm: useMoodForm,
			renderChart: (props) => (
				<MoodChart {...props} tracker={trackerContentMap[TrackerAbbreviation.Mood]} />
			),
			renderTable: (props) => <MoodTrackerTable {...props} unit={capitalize(props.unit)} />,
			showConnectHealthDataCTA: false,
			showMonthHighlight: true,
			showHealthKitPermissionsModal: false,
		},
		[TrackerAbbreviation.Steps]: {
			...t('trackers.steps', { returnObjects: true }),
			unit: (count = 0) => t('trackers.steps.unit', { count }),
			lastEntry: (count = 0) => renderLastEntry(`${count.toLocaleString()}`),
			minChartScale: 500,
			graphic: <TrackerStepsGraphic />,
			canSetGoal: true,
			goalMetRule: GoalMetRule.Above,
			useForm: useIntegerForm,
			renderChart: (props) => (
				<TrackerBarChart {...props} tracker={trackerContentMap[TrackerAbbreviation.Steps]} />
			),
			renderTable: renderDefaultTable,
			showConnectHealthDataCTA: true,
			showMonthHighlight: true,
			showHealthKitPermissionsModal: false,
		},
		[TrackerAbbreviation.Movement]: {
			...t('trackers.movement', { returnObjects: true }),
			unit: (count = 0) => t('trackers.movement.unit', { count }),
			lastEntry: (count = 0) =>
				renderLastEntry(
					`${count}${t('trackers.movement.unit', { count }).charAt(0)}`, // 50m
					`${count} ${t('trackers.movement.unit', { count })}` // accessibility label
				),
			minChartScale: 30,
			graphic: <TrackerMovementGraphic />,
			canSetGoal: true,
			goalMetRule: GoalMetRule.Above,
			useForm: useNumberForm,
			renderChart: (props) => (
				<TrackerBarChart {...props} tracker={trackerContentMap[TrackerAbbreviation.Movement]} />
			),
			renderTable: renderDefaultTable,
			showConnectHealthDataCTA: false,
			showMonthHighlight: true,
			showHealthKitPermissionsModal: false,
		},
		[TrackerAbbreviation.Weight]: {
			...t('trackers.weight', { returnObjects: true }),
			unit: (count = 0) => t('trackers.weight.unit', { count }),
			lastEntry: (count = 0) => renderLastEntry(`${count} ${t('trackers.weight.unit', { count })}`),
			minChartScale: 180,
			graphic: <TrackerWeightGraphic />,
			canSetGoal: true,
			useForm: useNumberForm,
			renderChart: (props) => (
				<TrackerBarChart {...props} tracker={trackerContentMap[TrackerAbbreviation.Weight]} />
			),
			renderTable: (props) => <DefaultTrackerTable {...props} />,
			useAuxiliaryForm: useHeightForm,
			showConnectHealthDataCTA: true,
			showMonthHighlight: true,
			showHealthKitPermissionsModal: false,
		},
		[TrackerAbbreviation.Nutrition]: {
			...t('trackers.nutrition', { returnObjects: true }),
			unit: (count = 0) => t('trackers.nutrition.unit', { count }),
			lastEntry: (count = 0) => renderLastEntry(`${count}`),
			minChartScale: sliderDataRange,
			graphic: <TrackerNutritionGraphic />,
			canSetGoal: false,
			useForm: (props) =>
				useSliderForm({
					...props,
					labelLeft: t('trackers.nutrition.inputRangeLabelLeft'),
					labelRight: t('trackers.nutrition.inputRangeLabelRight'),
				}),
			renderChart: (props) => (
				<TrackerBarChart {...props} tracker={trackerContentMap[TrackerAbbreviation.Nutrition]} />
			),
			renderTable: renderDefaultTable,
			showConnectHealthDataCTA: false,
			showMonthHighlight: true,
			showHealthKitPermissionsModal: false,
		},
		[TrackerAbbreviation.Hydration]: {
			...t('trackers.hydration', { returnObjects: true }),
			unit: (count = 0) => t('trackers.hydration.unit', { count }),
			lastEntry: (count = 0) =>
				renderLastEntry(`${count} ${t('trackers.hydration.unit', { count })}`),
			minChartScale: 100,
			graphic: <TrackerHydrationGraphic />,
			canSetGoal: true,
			goalMetRule: GoalMetRule.Above,
			useForm: useNumberForm,
			renderChart: (props) => (
				<TrackerBarChart {...props} tracker={trackerContentMap[TrackerAbbreviation.Hydration]} />
			),
			renderTable: (props) => <DefaultTrackerTable {...props} />,
			showConnectHealthDataCTA: true,
			showMonthHighlight: true,
			showHealthKitPermissionsModal: false,
		},
		[TrackerAbbreviation.Sleep]: {
			...t('trackers.sleep', { returnObjects: true }),
			unit: (count = 0) => t('trackers.sleep.unit', { count }),
			secondaryUnit: (count = 0) => t('trackers.sleep.secondaryUnit', { count }),
			lastEntry: (count = 0) => {
				const hours = Math.floor(count / 60);
				const minutes = count % 60;
				return renderLastEntry(
					`${hours}${t('trackers.sleep.unit', {
						count: hours,
					}).charAt(0)}${
						minutes > 0
							? ` ${minutes}${t('trackers.sleep.secondaryUnit', { count: minutes }).charAt(0)}` // 7h 30m
							: ''
					}`,
					// accessibility label
					`${hours} ${t('trackers.sleep.unit', { count: hours })}${
						minutes > 0
							? ` ${minutes} ${t('trackers.sleep.secondaryUnit', { count: minutes })}`
							: ''
					}`
				);
			},
			maxValue: 1440,
			secondaryInput: {
				unit: t('trackers.sleep.secondaryUnit', { count: 0 }),
				conversionFactor: 60,
			},
			minChartScale: 8,
			graphic: <TrackerSleepGraphic />,
			canSetGoal: true,
			goalMetRule: GoalMetRule.Above,
			goalValue: (count = 0) => {
				const hours = Math.floor(count / 60);
				const minutes = count % 60;
				return `${hours} ${t('trackers.sleep.unit', {
					count: hours,
				})} ${minutes} ${t('trackers.sleep.secondaryUnit', { count: minutes })}`;
			},
			useForm: useIntegerForm,
			renderChart: (props) => (
				<TrackerBarChart
					{...props}
					rawData={props?.rawData?.map((item) => ({
						...item,
						value: item.value !== null ? item.value / 60 : null,
						goalValue: item.goalValue !== null ? item.goalValue / 60 : null,
					}))}
					tracker={trackerContentMap[TrackerAbbreviation.Sleep]}
				/>
			),
			renderTable: (props) => <SleepTrackerTable {...props} />,
			showConnectHealthDataCTA: true,
			showMonthHighlight: true,
			showHealthKitPermissionsModal: false,
		},
		[TrackerAbbreviation.StressExperience]: {
			...t('trackers.stressExperience', { returnObjects: true }),
			unit: (count = 0) => t('trackers.stressExperience.unit', { count }),
			lastEntry: (count = 0) => renderLastEntry(`${count}`),
			minChartScale: sliderDataRange,
			graphic: <TrackerStressExperienceGraphic />,
			canSetGoal: false,
			useForm: (props) =>
				useSliderForm({
					...props,
					labelLeft: t('trackers.stressExperience.inputRangeLabelLeft'),
					labelRight: t('trackers.stressExperience.inputRangeLabelRight'),
				}),
			renderChart: (props) => (
				<TrackerBarChart
					{...props}
					tracker={trackerContentMap[TrackerAbbreviation.StressExperience]}
				/>
			),
			renderTable: renderDefaultTable,
			showConnectHealthDataCTA: false,
			showMonthHighlight: true,
			showHealthKitPermissionsModal: false,
		},
		[TrackerAbbreviation.StressManagement]: {
			...t('trackers.stressManagement', { returnObjects: true }),
			unit: (count = 0) => t('trackers.stressManagement.unit', { count }),
			lastEntry: (count = 0) => renderLastEntry(`${count}`),
			minChartScale: likertDataRange,
			graphic: <TrackerStressManagementGraphic />,
			canSetGoal: false,
			useForm: (props) =>
				useLikertScaleForm({
					...props,
					labelLeft: t('trackers.stressManagement.inputRangeLabelLeft'),
					labelCenter: t('trackers.stressManagement.inputRangeLabelCenter'),
					labelRight: t('trackers.stressManagement.inputRangeLabelRight'),
				}),
			renderChart: (props) => (
				<TrackerBarChart
					{...props}
					tracker={trackerContentMap[TrackerAbbreviation.StressManagement]}
				/>
			),
			renderTable: renderDefaultTable,
			showConnectHealthDataCTA: false,
			showMonthHighlight: true,
			showHealthKitPermissionsModal: false,
		},
		[TrackerAbbreviation.EmotionalHealth]: {
			...t('trackers.emotionalHealth', { returnObjects: true }),
			unit: (count = 0) => t('trackers.emotionalHealth.unit', { count }),
			lastEntry: (count = 0) => renderLastEntry(`${count}`),
			minChartScale: likertDataRange,
			graphic: <TrackerEmotionalHealthGraphic />,
			canSetGoal: false,
			useForm: (props) =>
				useLikertScaleForm({
					...props,
					labelLeft: t('trackers.emotionalHealth.inputRangeLabelLeft'),
					labelCenter: t('trackers.emotionalHealth.inputRangeLabelCenter'),
					labelRight: t('trackers.emotionalHealth.inputRangeLabelRight'),
				}),
			renderChart: (props) => (
				<TrackerBarChart
					{...props}
					tracker={trackerContentMap[TrackerAbbreviation.EmotionalHealth]}
				/>
			),
			renderTable: renderDefaultTable,
			showConnectHealthDataCTA: false,
			showMonthHighlight: true,
			showHealthKitPermissionsModal: false,
		},
		[TrackerAbbreviation.A1C]: {
			...t('trackers.a1c', { returnObjects: true }),
			unit: (count = 0) => t('trackers.a1c.unit', { count }),
			lastEntry: (count = 0) =>
				renderLastEntry(`${Number.isInteger(count) ? count.toFixed(1) : count.toString()}%`),
			minChartScale: 14,
			graphic: <TrackerA1cGraphic />,
			canSetGoal: false,
			useForm: useNumberForm,
			renderChart: () => <A1CTable />,
			isStandaloneChart: true,
			renderTable: (props) => <DefaultTrackerTable {...props} />,
			showConnectHealthDataCTA: true,
			showMonthHighlight: false,
			hideViewAllData: true,
			showHealthKitPermissionsModal: false,
		},
		[TrackerAbbreviation.BloodGlucose]: {
			...t('trackers.bloodGlucose', { returnObjects: true }),
			unit: (count = 0) => t('trackers.bloodGlucose.unit', { count }),
			lastEntry: (count = 0) => renderLastEntry(`${count} mg/dL`),
			minChartScale: 100,
			graphic: <TrackerBloodGlucoseGraphic />,
			canSetGoal: false,
			useForm: useBloodGlucoseForm,
			isStandaloneChart: true,
			renderChart: () => <BloodGlucoseChart />,
			renderTable: BloodGlucoseTable,
			showConnectHealthDataCTA: true,
			showMonthHighlight: false,
			showHealthKitPermissionsModal: true,
		},
		[TrackerAbbreviation.BloodPressure]: {
			...t('trackers.BloodPressure', { returnObjects: true }),
			unit: (count = 0) => t('trackers.BloodPressure.unit', { count }),
			lastEntry: (value = 0) => {
				const { systolic, diastolic } = bloodPressureNumberToObject(value);
				return renderLastEntry(`${systolic}/${diastolic}`);
			},
			minChartScale: 140,
			graphic: <TrackerBloodPressureGraphic />,
			canSetGoal: false,
			useForm: useBloodPressureForm,
			renderChart: (props) => (
				<BloodPressureChart
					{...props}
					tracker={trackerContentMap[TrackerAbbreviation.BloodPressure]}
				/>
			),
			renderTable: (props) => (
				<DefaultTrackerTable
					{...props}
					valueTransform={(value) => {
						const { systolic, diastolic } = bloodPressureNumberToObject(value);
						return `${systolic}/${diastolic}`;
					}}
				/>
			),
			showConnectHealthDataCTA: true,
			showMonthHighlight: false,
			showHealthKitPermissionsModal: true,
			disableScrollOnForm: true,
		},
	};

	return trackerContentMap;
}

import { noop } from 'lodash';
import * as React from 'react';

// Components and hooks in this file are only used on iOS so please look at the .ios module
// This module is for other platforms and is noop
export default function HealthKitPermissionsModal({ onPress }: { onPress?: () => void }) {
	return null;
}

export function useHealthKitPermissionsModal(transitionDelay = 250) {
	const [viewState, setViewState] = React.useState<
		'idle' | 'subscriptionsOutOfDate' | 'subscriptionsRefreshed'
	>('idle');

	return {
		viewState,
		refreshSubscriptions: ({ onRefresh }: { onRefresh?: () => void }) => {},
	};
}

// This is for testing only
export function _setValidicHealthKitSubscriptionsToOldSet() {
	noop();
}

import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import DateTimePicker, { DateTimePickerAndroid } from '@react-native-community/datetimepicker';
import { format, isToday, setHours, setMinutes } from 'date-fns';
import { noop } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, Pressable, View } from 'react-native';
import Icon from '../icon/Icon';
import Row from '../layout/Row';
import { Text } from '../text';
import InputCalendar from './InputCalendar';
import InputText, { IInputTextProps } from './InputText';

interface IInputCalendarProps extends Omit<IInputTextProps, 'value'> {
	value: Date;
	onChangeDate: (date: Date) => void;
	popupLabel?: string;
}

function InputDate({ value, onChangeDate }: IInputCalendarProps, ref: any) {
	const { t } = useTranslation();
	const styleRules = useStyleRules();
	const { vr } = useStyleHelpers();

	function handleDateSelection(date: Date) {
		const timeAdjustedDate = isToday(date) ? date : setMinutes(setHours(date, 12), 0);

		onChangeDate(timeAdjustedDate);
	}

	function handleAndroidInputPress() {
		DateTimePickerAndroid.open({
			value,
			mode: 'date',
			maximumDate: new Date(Date.now()),
			onChange: (event, date) => (date ? handleDateSelection(date) : noop()),
		});
	}

	if (Platform.OS === 'ios') {
		return (
			<Row style={vr(8)} wrap>
				<Row.Item fill>
					<Text weight="semiBold">Date</Text>
				</Row.Item>
				<Row.Item>
					<DateTimePicker
						value={value}
						maximumDate={new Date(Date.now())}
						mode="date"
						display="calendar"
						onChange={(event, date) => (date ? handleDateSelection(date) : noop())}
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						themeVariant={styleRules.settings.colorScheme}
					/>
				</Row.Item>
			</Row>
		);
	}

	if (Platform.OS === 'android') {
		return (
			<Pressable
				aria-label={t('forms.date.accessibilityLabel', { date: format(value, 'PP') })}
				onPress={handleAndroidInputPress}
			>
				<View style={{ pointerEvents: 'none' }}>
					<InputText
						ref={ref}
						label={t('track.entry.dateLabel')}
						type="date"
						inputRightContainerStyle={{ paddingRight: 0, height: '100%' }}
						inputRight={() => (
							<View
								style={{
									height: '100%',
									paddingRight: 10,
									justifyContent: 'center',
								}}
							>
								<Icon size={16} color="text" name="schedule" />
							</View>
						)}
						readOnly={true}
						value={format(value, 'MM/dd/yyyy')}
					/>
				</View>
			</Pressable>
		);
	}

	return (
		<InputCalendar
			ref={ref}
			label={t('track.entry.dateLabel')}
			popupLabel={t('track.entry.calendarTitle')}
			value={value}
			onChangeDate={handleDateSelection}
			preserveTime
		/>
	);
}

export default React.forwardRef(InputDate);

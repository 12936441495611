import * as React from 'react';
import { Appearance, Platform } from 'react-native';
import { colorSchemeEvent, fontScaleEvent } from './analyticEvents';
import { useStyleRules } from './styleRules/useStyleRules';

function setCSSVariable(variable: string, value: string) {
	if (Platform.OS === 'web') {
		document.documentElement.style.setProperty(variable, value);
	}
}

export default function useGlobalStyleConfig() {
	const { colors, settings } = useStyleRules();

	React.useEffect(() => fontScaleEvent(settings.fontScale), [settings.fontScale]);

	React.useEffect(() => {
		colorSchemeEvent(settings.colorScheme);

		// Set appearance for native UI elements like alerts and keyboard
		if (Platform.OS !== 'web') {
			Appearance.setColorScheme(settings.colorScheme);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		setCSSVariable('--color-text', colors.text);
		setCSSVariable('--color-text-light', colors.textLight);
		setCSSVariable('--color-primary', colors.primary);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [settings.colorScheme]);
}

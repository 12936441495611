import {
	useAllContentQuery,
	useContentDetailQuery,
	useSetFavoriteStatusMutation,
} from '@mobe/api/explore/exploreApiHooks';
import ChatButton from '@mobe/components/chatButton/ChatButton';
import Row from '@mobe/components/layout/Row';
import useSubScreenStyleProps from '@mobe/utils/styles/navigation/useSubScreenStyleProps';
import useTransitions from '@mobe/utils/useTransitions';
import { useFocusEffect } from '@react-navigation/native';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as React from 'react';
import { Platform } from 'react-native';
import {
	ExploreDetailScreensNavigationProp,
	ExploreDetailScreensRouteProp,
} from '../../navigation/modal/types';
import ExploreFavoriteButton from '../components/ExploreFavoriteButton';
import SharedContentView from '../components/SharedContentView';

export const EXPLORE_CONTENT_ID_PARAM_KEY = 'contentId';
export const EXPLORE_CONTENT_TYPE_PARAM_KEY = 'contentType';

export type ExploreContentType = 'sharedContent' | 'shareableContent';

export interface IExploreDetailScreenParams {
	[EXPLORE_CONTENT_ID_PARAM_KEY]: number;
	[EXPLORE_CONTENT_TYPE_PARAM_KEY]: ExploreContentType;
}

interface IExploreDetailScreenProps {
	navigation: ExploreDetailScreensNavigationProp;
	route: ExploreDetailScreensRouteProp;
}

export default function ExploreDetailScreen({ navigation, route }: IExploreDetailScreenProps) {
	const allContentQuery = useAllContentQuery();
	const contentId =
		route.params[EXPLORE_CONTENT_TYPE_PARAM_KEY] === 'shareableContent'
			? allContentQuery.data
					?.flatMap((category) => category.items)
					.find((content) => content.shareableContentId === Number(route.params.contentId))
					?.sharedContentId
			: route.params.contentId;
	const contentItem = useContentDetailQuery(contentId || null);

	useFocusEffect(() => {
		navigation.setOptions({
			title: contentItem.data?.title || '',
		});
	});

	// Kick PPT back to Explore screen if deep link content ID is not found
	React.useEffect(() => {
		if (allContentQuery.isFetched && contentId === undefined) {
			navigation.navigate('MENU_STACK', {
				screen: 'BOTTOM_TAB_STACK',
				params: { screen: 'EXPLORE_SCREEN' },
			});
		}
	}, [allContentQuery.isFetched, contentId, navigation]);

	return contentId !== undefined ? <SharedContentView contentId={contentId} /> : null;
}

export function useExploreDetailScreenOptions() {
	const allContentQuery = useAllContentQuery();
	const subScreenStyleProps = useSubScreenStyleProps({ hasExtraIcons: true });
	const transitions = useTransitions();

	return (screenProps: IExploreDetailScreenProps): StackNavigationOptions => ({
		title: '',
		headerTitle: '',
		...subScreenStyleProps,
		...(Platform.OS === 'android' && transitions.DefaultTransition),
		headerRight: function HeaderRight() {
			const contentId =
				screenProps.route.params[EXPLORE_CONTENT_TYPE_PARAM_KEY] === 'shareableContent'
					? allContentQuery.data
							?.flatMap((category) => category.items)
							.find(
								(content) =>
									content.shareableContentId === Number(screenProps.route.params.contentId)
							)?.sharedContentId
					: screenProps.route.params.contentId;
			const setFavoriteStatusMutation = useSetFavoriteStatusMutation();
			const favorited = useContentDetailQuery(contentId || null).data?.isFavorite;

			return (
				<Row gutterSize={20} align="top">
					{contentId !== undefined && (
						<Row.Item>
							<ExploreFavoriteButton
								favorited={favorited}
								onPress={() => {
									setFavoriteStatusMutation.mutate({
										sharedContentId: contentId,
										isFavorite: !favorited,
									});
								}}
							/>
						</Row.Item>
					)}
					<Row.Item>
						<ChatButton />
					</Row.Item>
				</Row>
			);
		},
	});
}

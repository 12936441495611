import { useLogoutMutation, useMessagingTokenQuery } from '@mobe/api/authentication/authApiHooks';
import { useAppFeedback } from '@mobe/components/appFeedbackPopup/AppFeedbackProvider';
import { Button } from '@mobe/components/button';
import CardButton from '@mobe/components/cardButton/CardButton';
import Heading from '@mobe/components/heading/Heading';
import Row from '@mobe/components/layout/Row';
import FastImage from '@mobe/components/mobeImage/fastImage';
import { Switch } from '@mobe/components/switch';
import { Text } from '@mobe/components/text';
import { useToast } from '@mobe/components/toast/Toast';
import env from '@mobe/env/env';
import { useAccessibilityAutoFocus, useAccessibilityFocus } from '@mobe/utils/a11y';
import { useClipboard } from '@mobe/utils/clipboard';
import crashlytics from '@mobe/utils/crashlytics';
import { useHasSeenWelcomeVideo } from '@mobe/utils/storage/appStateStorage';
import { storage } from '@mobe/utils/storage/storage';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import { usePersistentState } from '@mobe/utils/usePersistentState';
import { uniqueId } from 'lodash';
import * as React from 'react';
import { Alert, Platform, ScrollView, StyleSheet } from 'react-native';
import { DevToolsScreenNavigationProp } from '../navigation/menu/types';
import { _setValidicHealthKitSubscriptionsToOldSet } from '../track/components/HealthKitPermissionsModal';

export interface IDevToolsScreenProps {
	navigation: DevToolsScreenNavigationProp;
}

export default function DevToolsScreen({ navigation }: IDevToolsScreenProps) {
	const styleHelpers = useStyleHelpers();
	const styleRules = useStyleRules();
	const persistentState = usePersistentState();
	const [hasSeenWelcomeVideo, setHasSeenWelcomeVideo] = useHasSeenWelcomeVideo();
	const [clipboardValue, setClipboardValue] = useClipboard();
	const [hasError, setHasError] = React.useState(false);
	const toast = useToast();
	const appFeedback = useAppFeedback();
	const messagingTokenQuery = useMessagingTokenQuery();
	const autoFocusTestRef = useAccessibilityAutoFocus();
	const [testButtonRef, setFocusToTestButton] = useAccessibilityFocus();
	const logoutMutation = useLogoutMutation();

	React.useEffect(() => {
		if (hasError) {
			throw new Error();
		}
	}, [hasError]);

	function handleDeviceTokenPress() {
		setClipboardValue(messagingTokenQuery.data || '');
		Alert.alert('Device token copied to clipboard.');
	}

	const styles = StyleSheet.create({
		row: {
			paddingVertical: 16,
			borderBottomColor: styleRules.colors.stroke,
			borderBottomWidth: 1,
		},
	});

	return (
		<ScrollView contentContainerStyle={styleHelpers.wrapper}>
			{Platform.OS !== 'web' && messagingTokenQuery.isSuccess ? (
				<CardButton ref={autoFocusTestRef} onPress={handleDeviceTokenPress}>
					<Heading>FCM Device Token</Heading>
					<Text>{messagingTokenQuery.data}</Text>
				</CardButton>
			) : null}
			<Row style={styles.row}>
				<Row.Item fill>
					<Text weight="semiBold">API &amp; App Version</Text>
				</Row.Item>
				<Row.Item>
					<Text>
						{env.ENV_NAME} / {env.APP_VERSION}
					</Text>
				</Row.Item>
			</Row>
			<Row style={styles.row}>
				<Row.Item fill>
					<Text weight="semiBold">Show welcome video</Text>
				</Row.Item>
				<Row.Item>
					<Switch
						value={!hasSeenWelcomeVideo}
						onValueChange={() => {
							setHasSeenWelcomeVideo(!hasSeenWelcomeVideo);
						}}
					/>
				</Row.Item>
			</Row>
			<Button
				style={styleHelpers.vrTop(6)}
				title="Clear all secure persistent data"
				onPress={() => {
					persistentState.clearAll();
					logoutMutation.mutate({});
					alert('Data cleared; You have been logged out');
				}}
			/>
			<Button
				style={styleHelpers.vrTop(6)}
				title="Clear all local data"
				onPress={() => storage.clearAll()}
			/>
			<Button
				style={styleHelpers.vrTop(6)}
				title="Focus Next Button"
				onPress={() => {
					setFocusToTestButton();
				}}
			/>
			<Button
				ref={testButtonRef}
				style={styleHelpers.vrTop(6)}
				title="Show toast"
				onPress={() => {
					toast.show(uniqueId('toast'));
				}}
			/>
			{Platform.OS !== 'web' && (
				<Button
					style={styleHelpers.vrTop(6)}
					title="Maybe show app feedback"
					onPress={() => appFeedback.show(0)}
				/>
			)}
			{Platform.OS !== 'web' && (
				<Button
					style={styleHelpers.vrTop(6)}
					title="Force app feedback to show"
					onPress={() => appFeedback._forceShow(0)}
				/>
			)}
			<Button
				style={styleHelpers.vrTop(6)}
				title="Crash app"
				onPress={() => {
					crashlytics.crash();
				}}
			/>
			<Button
				style={styleHelpers.vrTop(6)}
				title="Throw error"
				onPress={() => {
					setHasError(true);
				}}
			/>
			<Button
				style={styleHelpers.vrTop(6)}
				title="Clear image cache"
				onPress={() => {
					FastImage.clearDiskCache();
					FastImage.clearMemoryCache();
				}}
			/>
			<Text style={styleHelpers.vrTop(6)}>Set validic health kit subscriptions to old set</Text>
			<Button
				style={styleHelpers.vrTop(3)}
				title="Set subscriptions"
				onPress={_setValidicHealthKitSubscriptionsToOldSet}
			/>
		</ScrollView>
	);
}

export function devToolsScreenOptions() {
	return {
		title: 'Dev Tools',
	};
}

import * as React from 'react';
import { View } from 'react-native';
import Svg, { Circle, Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

export default function TrackerBloodGlucoseGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(377, 377);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					d="M308.333 38.012c-.016-12.812-5.893-22.85-16.384-30.273-8.626-6.096-18.478-7.069-28.657-6.677-1.431.761.31.239-.648.971.487.55.648 1.013.648-.621-.052.122.434-.125-.38-.118.127.046 8.618-.119.38-.232-.231-.003.16 1.533 0 .35-.099-.776-.207.316.12-.355 0 0 2.837.775 1.9-.054-2.406 0-34.216.082-36.622.074-1.805 0-3.617 0-5.421-.007H98.974c-2.109.324-4.248.557-6.304 1.093C76.972 6.24 67.63 16.421 63.831 31.855c-.175 1.869-.51 3.73-.51 5.599-.023 75.187-.091 150.382.031 225.569.03 19.412 16.871 36.225 36.467 36.286 57.321.173 114.634.165 171.954 0 19.864-.053 36.552-16.851 36.575-36.49.076-74.938.084-149.877-.015-224.823v.016Z"
					fill="#d1d1d1"
				/>
				<Path
					d="M201.406 296.737c0 .965 0 1.929.008 2.894-.03 20.709-.068 41.426-.099 62.135-.008 6.714-1.979 8.711-8.77 8.779-3.678.038-7.355.053-11.032-.03-4.865-.113-6.89-2.133-6.898-6.963-.038-19.835-.015-39.67-.053-59.505 0-1.469 0-2.62-.289-4.416 0-1.822.126-1.822 0-2.894-.054-2.359 1.758-2.517 3.365-2.547 6.89-.128 13.787-.143 20.685.015 1.477.03 3.563-.09 3.083 2.54v-.008Z"
					fill="silver"
				/>
				<Path
					d="M289.117 124.826c0 26.745-.426 53.498.145 80.229.411 19.277-15.577 31.056-31.062 31.078-21.942.03-43.891.03-65.833.03-25.877 0-51.755.015-77.625-.022-11.542-.015-20.929-4.386-27.598-13.889-2.817-4.009-4.279-8.516-4.286-13.429 0-56.249-.069-112.498.038-168.747.023-13.754 12.333-25.721 27.278-27.107 1.637-.151 3.297-.196 4.941-.196 47.188-.008 94.367-.008 141.554 0 12.638 0 22.604 4.86 29.121 15.84 2.505 4.213 3.327 8.878 3.327 13.731-.015 27.499-.007 54.998-.007 82.489l.007-.007Z"
					fill="#fefefe"
				/>
				<Path
					d="M301.205 43.119c.365 72.316 4.842 144.738-.853 217.114-5.2-72.361.069-144.685.853-217.114Z"
					fill="#fbfcfc"
				/>
				<Path
					d="M201.42 296.737c-.137-1.779-1.432-1.726-2.733-1.726-7.187.015-14.382.023-21.569-.008-1.363 0-2.535.114-2.824 1.734-1.554-.008-3.381.09-3.137-2.14.198-1.817 1.911-1.689 3.304-1.689 8.854 0 17.716 0 26.57.008 1.394 0 3.076-.143 3.259 1.726.183 1.914-1.37 2.095-2.855 2.095h-.015Z"
					fill="#adadad"
				/>
				<Circle cx={188.091} cy={358.24} r={7.82} fill="#fff" />
				<Path
					d="M185.765 215.009h-58.166c-.89 0-1.773.023-2.664-.007-3.776-.121-6.205-1.892-6.357-4.597-.16-2.886 2.299-4.914 6.258-5.147.761-.045 1.522-.008 2.284-.008h117.861c5.375 0 8.474 1.884 8.36 5.049-.114 3.03-3.038 4.703-8.261 4.703h-59.307l-.008.007ZM132.93 189.364h106.464c1.142 0 2.315-.12 3.411.113 2.528.543 4.583 1.809 4.606 4.688.023 3.052-2.033 4.431-4.789 4.876-1.241.196-2.527.098-3.799.098H132.739c-5.633 0-8.443-1.591-8.496-4.786-.053-3.316 2.855-4.989 8.687-4.989ZM186.043 182.762c-15.455 0-30.903-.015-46.358.008-2.367 0-4.583-.173-5.877-2.555-1.477-2.713-.152-6.014 2.931-6.903 1.188-.339 2.497-.331 3.746-.339 30.27-.015 60.541-.015 90.819-.007.883 0 1.789-.061 2.657.075 2.878.467 4.659 2.087 4.514 5.079-.137 2.962-1.949 4.537-4.941 4.612-3.037.076-6.083.038-9.12.038h-38.379l.008-.008Z"
					fill="#ddd"
					fillOpacity={0.85}
				/>
				<Path
					d="M185.353 57.874c.258-.348.413-.469.465-.643 3.203-10.446 10.573-16.043 20.802-18.32 16.188-3.58 31.653 3.407 38.403 17.659 5.494 11.611 4.943 23.291-.758 34.711-4.081 8.169-10.074 14.878-16.428 21.257-10.195 10.255-21.268 19.554-31.652 29.6-3.238 3.129-6.407 6.31-9.575 9.491-.724.73-1.223 1.147-2.136.173-12.829-13.401-27.157-25.202-40.331-38.221-6.751-6.674-13.123-13.679-17.479-22.283-4.753-9.281-5.873-18.997-2.98-29.079 4.805-16.738 20.7-26.576 38.128-23.742 8.059 1.303 14.999 4.484 19.855 11.419 1.671 2.381 2.721 5.058 3.686 7.995v-.017Z"
					fill="#cb1e00"
				/>
				<Path
					d="M185.195 152.33c-.215-.072-.45-.239-.721-.528-12.829-13.401-27.157-25.202-40.331-38.221-6.751-6.674-13.123-13.679-17.479-22.283-4.753-9.281-5.873-18.997-2.98-29.079.224-.78.472-1.544.743-2.294a64.621 64.621 0 0 1 6.817-11.134 87.32 87.32 0 0 0 13.382 25.848c4.392 5.888 9.241 11.447 15.057 16.028 5.762 4.518 12.085 8.137 18.599 11.473a226.43 226.43 0 0 0 43.193 16.594c-8.341 7.9-17.033 15.416-25.29 23.404-3.238 3.129-6.407 6.31-9.575 9.491-.316.319-.589.578-.873.687-.18.006-.361.01-.542.014Z"
					fill="#dd2b0c"
				/>
			</Svg>
		</View>
	);
}

import { useContentModulesQuery } from '@mobe/api/explore/exploreApiHooks';
import ChatButton from '@mobe/components/chatButton/ChatButton';
import Heading from '@mobe/components/heading/Heading';
import VrArray from '@mobe/components/layout/VrArray';
import ScreenTemplate from '@mobe/components/screenTemplate/ScreenTemplate';
import { Text } from '@mobe/components/text';
import { useRestoreFocus } from '@mobe/utils/a11y';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useSubScreenStyleProps from '@mobe/utils/styles/navigation/useSubScreenStyleProps';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as React from 'react';
import { View } from 'react-native';
import {
	ExploreContentModuleScreenNavigationProp,
	ExploreContentModuleScreenRouteProp,
} from '../../navigation/modal/types';
import ContentCard from '../components/ContentCard';

export const CONTENT_MODULE_ID_PARAM_KEY = 'contentModuleId';

export interface IExploreContentModuleScreenParams {
	[CONTENT_MODULE_ID_PARAM_KEY]: number;
}

interface IExploreContentModuleScreenProps {
	navigation: ExploreContentModuleScreenNavigationProp;
	route: ExploreContentModuleScreenRouteProp;
}

export default function ExploreContentModuleScreen({
	navigation,
	route,
}: IExploreContentModuleScreenProps) {
	const styleRules = useStyleRules();
	const { vr } = useStyleHelpers();
	const contentModulesQuery = useContentModulesQuery();
	const contentModulesData = contentModulesQuery.data || [];
	const contentModuleData = contentModulesData.find(
		(module) => module.contentModuleId === Number(route.params.contentModuleId)
	);
	const featuredContent = contentModuleData?.sharedContent[0];
	const { storeFocusTarget, hasStoredFocusTarget } = useRestoreFocus();

	if (!contentModuleData) {
		return null;
	}

	return (
		<ScreenTemplate scrollViewBackgroundColor={styleRules.colors.background}>
			<Heading accessibilityAutoFocus={!hasStoredFocusTarget} level="h1" style={vr(2)}>
				{contentModuleData.title}
			</Heading>
			<Text size="lg" color="light" style={vr(8)}>
				{contentModuleData.longDescription}
			</Text>
			{featuredContent && (
				<View style={vr(10)}>
					<ContentCard
						variant="featured"
						onPress={(event) => {
							storeFocusTarget(event);
							navigation.navigate('EXPLORE_DETAIL_SCREEN', {
								contentId: featuredContent.sharedContentId,
								contentType: 'sharedContent',
							});
						}}
						contentItem={featuredContent}
						description={contentModuleData.heroDescription}
					/>
				</View>
			)}
			<VrArray increment={5}>
				{contentModuleData.sharedContent.map((item, i) =>
					// omit first card here since it's already been rendered
					i === 0 ? null : (
						<ContentCard
							key={`${i}_${item.sharedContentId}`}
							variant="compact"
							onPress={(event) => {
								storeFocusTarget(event);
								navigation.navigate('EXPLORE_DETAIL_SCREEN', {
									contentId: item.sharedContentId,
									contentType: 'sharedContent',
								});
							}}
							contentItem={item}
						/>
					)
				)}
			</VrArray>
		</ScreenTemplate>
	);
}

export function useExploreContentModuleScreenOptions() {
	const subScreenStyleProps = useSubScreenStyleProps();
	const contentModulesQuery = useContentModulesQuery();
	const contentModulesData = contentModulesQuery.data || [];

	return (props: IExploreContentModuleScreenProps): StackNavigationOptions => {
		const id = Number(props.route.params.contentModuleId);
		const contentModuleData = contentModulesData.find((module) => module.contentModuleId === id);

		return {
			title: contentModuleData?.screenTitle || '',
			...subScreenStyleProps,
			headerRight: () => <ChatButton />,
		};
	};
}

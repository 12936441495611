import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

export default function TrackerA1cGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(350, 350);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					fill="#f6f6f6"
					d="M165.642 348.637c30.927 4.24 54.583-15.27 59.529-42.495 1.089-5.979 3.327-8.408 8.759-10.617 23.979-9.792 32.193-31.672 25.908-52.198-5.579-18.214-15.781-34.411-25.908-50.52-3.298-5.242-7.993-10.071-8.008-16.815-.089-37.518-.03-75.036-.059-112.54 0-3.165.648-4.903 4.166-6.464 13.557-6.051 19.637-19.568 16.398-34.661-2.502-11.662-14.735-20.791-28.425-20.835-28.925-.089-57.851-.059-86.777.014-13.557.03-25.804 9.218-28.336 20.953-3.077 14.254 2.723 28.316 15.397 33.94 4.843 2.15 5.182 4.683 5.167 8.762-.059 38.99-.044 77.98-.044 116.956h-.059c0 26.475-.442 52.979.162 79.439.397 17.286-2.444 34.72 2.546 51.874 4.902 16.845 22.626 32.865 39.584 35.192"
				/>
				<Path
					fill="#28aa91"
					d="M165.648 348.651c-16.96-2.326-34.685-18.347-39.587-35.193-4.991-17.154-2.15-34.589-2.547-51.876-.604-26.475-.162-52.966-.162-79.441h.059c0-38.992-.03-77.984.044-116.961 0-4.093-.324-6.611-5.167-8.761-12.676-5.64-18.476-19.717-15.399-33.956 2.532-11.736 14.78-20.924 28.339-20.954 28.928-.088 57.857-.103 86.785 0 13.676.044 25.91 9.159 28.413 20.807 3.239 15.093-2.841 28.61-16.4 34.662-3.504 1.561-4.166 3.299-4.166 6.465.029 37.519-.03 75.038.058 112.543.015 6.729 4.726 11.574 8.009 16.816 10.129 16.094 20.346 32.306 25.91 50.521 6.287 20.527-1.928 42.408-25.91 52.2-5.417 2.209-7.67 4.639-8.759 10.617-4.947 27.227-28.605 46.737-59.535 42.497"
				/>
				<Path
					fill="#bbe4dc"
					d="M158.753 181.341c-5.683 3.057-13.258-1.702-17.239 3.081-3.995 4.798-.11 12.638-1.181 19.074-.041.234-.041.482-.041.731-.041 4.33 1.386 6.904 6.04 6.626 3.363-.205 6.836-.614 10.103 1.112 3.336 1.755 4.75 4.52 4.022 8.323-.686 3.642-2.992 5.119-6.452 5.075-2.951-.044-5.916.176-8.854.527-1.771.219-4.296.512-4.392 2.75-.316 8.001-1.565 16.411.315 23.871 1.085 4.286 8.223 1.009 12.547 1.902.22.043.453.029.687.043 4.118.264 6.328 2.706 6.342 7.007.013 4.563-2.156 7.328-6.658 7.46-2.965.087-5.944.16-8.909 0-3.418-.191-4.983 1.067-4.86 4.987.22 7.051-.192 14.13.124 21.166 1.125 24.208 14.221 35.69 29.363 38.074 19.492 3.072 34.757-12.009 37.763-27.923 1.14-5.997.055-8.22-5.216-9.829-21.085-6.466-32.808-32.312-25.368-54.047 5.505-16.076 13.686-30.556 21.524-45.3 3.336-6.261 9.555-11.219 9.87-18.767.591-14.334.151-28.713.179-43.062 0-2.399-.481-4.608-2.938-5.061-8.264-1.507-17.077-4.812-24.682.541-11.558 8.147-23.075 7.811-35.128 2.604-4.091-1.77-5.408-.161-5.408 3.978 0 8.762.096 17.524-.042 26.285-.054 4.052 1.51 5.807 5.299 5.602 9.451.612 9.558-.561 13.522 1.478 4.271 2.197 5.134 7.71 0 11.555l-.332.137Z"
				/>
				<Path
					fill="#6bc5b4"
					d="M246.202 253.432c-.092-.856.076-3.213-.612-5.285-6.09-18.121-16.204-34.215-26.471-50.234-2.616-4.083-4.544-1.756-6.074.766-8.966 14.683-18.453 29.11-24.237 45.429-4.223 11.906-2.341 22.43 7.467 31.213 9.67 8.662 20.412 10.254 32.362 5.359 10.159-4.158 17.443-14.818 17.58-27.233l-.015-.015Z"
				/>
				<Path
					fill="#f1f6f6"
					d="M174.088 17.15c-13.7 0-27.385-.059-41.085.029-9.355.059-15.158 5.369-15.259 13.687-.116 8.728 5.731 14.376 15.389 14.391 27.385.073 54.77.059 82.156-.059 9.643-.044 15.114-5.119 15.244-13.701.13-8.861-5.558-14.288-15.36-14.347-13.7-.088-27.385-.029-41.085 0ZM144.859 61.226h61.36c4.037-.071 5.498 1.55 5.44 5.61-.188 13.845-.247 27.689.028 41.534.102 5.05-2.386 5.724-6.235 4.821-12.588-2.941-24.148-.832-35.274 5.753-6.496 3.845-23.887-.015-29.11-5.452-1.375-1.42-1.36-2.941-1.36-4.591 0-14.318.072-28.651-.044-42.969-.029-3.744 1.534-4.763 5.195-4.706Z"
				/>
				<Path
					fill="#abddd4"
					d="M162.146 301.704c-2.916-2.458-5.346-5.44-7.548-8.598-5.138-5.698-9.259-9.336-14.311-12.604 0 0-.081 10.201-.084 11.834-.012 8.333.888 11.686 1.095 13.048 1.634 6.536 2.698 9.209 5.049 13.157 2.352 3.947 5.433 7.355 9.06 10.02a32.073 32.073 0 0 0 12.054 5.49c4.326.958 8.792.988 13.129.087a32.023 32.023 0 0 0 12.118-5.33c3.66-2.617 7.415-7.083 9.05-10.351-10.589-2.128-20.703-5.398-30.288-10.598-3.265-1.79-6.435-3.731-9.324-6.155Z"
				/>
			</Svg>
		</View>
	);
}

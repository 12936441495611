/* eslint-disable react/prop-types */
import { useTrackerEntriesQuery } from '@mobe/api/track/trackApiHooks';
import { useGetTrackerIdFromAbbreviation } from '@mobe/api/track/trackApiUtils';
import { ITrackerDetail, TrackerAbbreviation } from '@mobe/api/track/trackService';
import Box from '@mobe/components/box/Box';
import { Text } from '@mobe/components/text';
import { TrackDetailScreenNavigationProp } from '@mobe/features/navigation/modal/types';
import { useNavigation } from '@react-navigation/native';
import { subYears } from 'date-fns';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import DefaultTrackerTable from '../DefaultTrackerTable';

export default function A1CTable() {
	const { t } = useTranslation();
	const navigation = useNavigation<TrackDetailScreenNavigationProp>();
	const getTrackerIdFromAbbreviation = useGetTrackerIdFromAbbreviation();
	const trackerEntriesQuery = useTrackerEntriesQuery({
		trackerTypeId: getTrackerIdFromAbbreviation(TrackerAbbreviation.A1C),
		startDate: subYears(Date.now(), 1),
		endDate: new Date(Date.now()),
		page: 1,
		limit: 100,
	});

	const entries = trackerEntriesQuery.data?.data;

	function handleEditEntryPress(trackerEntry: ITrackerDetail) {
		navigation.navigate('TRACK_ENTRY_MODAL_SCREEN', {
			trackerAbbreviation: TrackerAbbreviation.A1C,
			trackerEntry,
		});
	}

	return (
		<>
			<Box style={{ paddingHorizontal: 8, paddingVertical: 12 }}>
				<Text weight="semiBold">{t('trackers.a1c.chartLabel')}</Text>
			</Box>
			<DefaultTrackerTable
				entries={entries}
				unit={t('trackers.a1c.unit')}
				onRowPress={handleEditEntryPress}
				valueTransform={(value) => {
					return Number.isInteger(value) ? value.toFixed(1) : value.toString();
				}}
			/>
		</>
	);
}

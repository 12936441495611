/* eslint-disable react/prop-types */
import { TrackerAggregatedEntriesInterval } from '@mobe/api/track/trackService';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import useChartTheme, { getBarWidth } from '@mobe/utils/styles/useChartTheme';
import { max } from 'lodash';
import * as React from 'react';
import { View } from 'react-native';
import {
	BarProps,
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryClipContainer,
	VictoryLine,
	VictoryScatter,
} from 'victory-native';
import { GoalMetRule, ITrackerChartProps, ITrackerContent } from '../../useTrackerContent';
import Legend from './Legend';
import Sparkles from './Sparkles';
import useXAxisProps from './useXAxisProps';

export interface ITrackerBarChartProps extends ITrackerChartProps {
	tracker: ITrackerContent;
}

export const TRACKER_BAR_CHART_MIN_HEIGHT = 250;

export default function TrackerBarChart({
	tracker,
	rawData = [],
	interval = TrackerAggregatedEntriesInterval.Day,
	chartHeight = TRACKER_BAR_CHART_MIN_HEIGHT,
	customYTickLabelComponent,
	hideLegend = false,
	padding = {},
}: ITrackerBarChartProps) {
	const styleRules = useStyleRules();

	// Mapping date strings to date ms numbers because it was fixing an issue with victory y axis breaking with date objects
	const data = React.useMemo(
		() => rawData.map((dataPoint) => ({ ...dataPoint, date: new Date(dataPoint.date).getTime() })),
		[rawData]
	);
	const xAxisProps = useXAxisProps(data, interval);

	const highestValue = max(data.map((entry) => entry.value));
	const highestGoalValue = max(data.map((entry) => entry.goalValue)) || 0;
	const yMax = React.useMemo(
		() => Math.max(highestGoalValue, highestValue || tracker.minChartScale, tracker.minChartScale),
		[highestValue, highestGoalValue, tracker.minChartScale]
	);

	const yAxisOffsetWidth = 10 + Math.round(yMax).toString().length * 9;

	// Chart theme and common style props
	const chartTheme = useChartTheme();
	const chartPadding = Object.assign(
		{},
		{ top: 30, bottom: 40, left: 0, right: yAxisOffsetWidth },
		padding
	);

	// Width for chart needs to be set for chart to have proper aspect ratio
	// https://formidable.com/open-source/victory/docs/common-props/#width
	const [chartContainerWidth, setChartContainerWidth] = React.useState<number | undefined>(
		undefined
	);

	return (
		<>
			<View
				style={{ minHeight: chartHeight, width: '100%' }}
				onLayout={(event) => setChartContainerWidth(event.nativeEvent.layout.width)}
			>
				{chartContainerWidth ? (
					<>
						<VictoryChart
							height={chartHeight}
							width={chartContainerWidth}
							domain={{ y: [0, yMax] }}
							minDomain={{ y: 0 }}
							maxDomain={{ y: yMax }}
							padding={chartPadding}
							domainPadding={{ x: [20, 20], y: [0, 20] }}
							theme={chartTheme}
						>
							{/* Y Axis */}
							<VictoryAxis
								dependentAxis
								orientation="right"
								crossAxis={false} // Force zero tick to show
								tickFormat={(value: number) => Math.round(value).toLocaleString()}
								tickLabelComponent={customYTickLabelComponent}
								tickCount={5}
							/>

							{/* Goal Line */}
							{/* Checking length of data is greater than zero because component was erroring with empty dataset */}
							{data.length > 0 ? (
								<VictoryLine
									data={data}
									x="date"
									y="goalValue"
									interpolation="step"
									groupComponent={<VictoryClipContainer clipPadding={{ top: 1 }} />}
									style={{ data: { stroke: styleRules.colors.goal, strokeWidth: 2 } }}
								/>
							) : null}

							{/* Bars */}
							<VictoryBar
								data={data}
								x="date"
								y="value"
								barWidth={getBarWidth}
								cornerRadius={{
									// Round the top of the bars
									top: (props) => {
										// Docs say we're getting BarProps when using a function
										const barProps = props as BarProps;

										if (barProps.barWidth && typeof barProps.barWidth === 'number') {
											return Math.round(barProps.barWidth / 2);
										}

										return 0;
									},
								}}
								groupComponent={<VictoryClipContainer />}
							/>

							{/* X Axis */}
							<VictoryAxis {...xAxisProps} />

							{/* Scatter Chart to render sparkles */}
							<VictoryScatter
								data={data}
								x="date"
								y="value"
								dataComponent={<ScatterDataComponent goalMetRule={tracker.goalMetRule} />}
							/>
						</VictoryChart>
					</>
				) : null}
			</View>

			{!hideLegend && highestGoalValue > 0 && <Legend />}
		</>
	);
}

// TODO: @WARNING The code below is working around some victory chart typescript deficiencies, please be cautious
interface ICustomScatterDataComponentProps {
	goalMetRule: GoalMetRule | undefined;
}

// https://formidable.com/open-source/victory/docs/victory-scatter#datacomponent
interface IVictoryScatterDataComponentProps {
	x: number;
	y: number;
	index: number;
	datum: {
		value: number;
	};

	// This component gets access to the entire dataset
	data: {
		date: number; // Mapping date strings to date ms numbers above because it was fixing an issue with victory y axis breaking with date objects
		value: number;
		goalValue: number | null;
	}[];
}

type ScatterDataComponentProps = ICustomScatterDataComponentProps &
	IVictoryScatterDataComponentProps;

const GRAPHIC_Y_OFFSET = 28;
const GRAPHIC_X_OFFSET = 10;

function ScatterDataComponent(props: ICustomScatterDataComponentProps) {
	const { index, x, y, datum, data, goalMetRule } = props as ScatterDataComponentProps;
	const currentDataPoint = data[index];

	const showCustomScatterPoint =
		Boolean(goalMetRule) &&
		currentDataPoint &&
		currentDataPoint.goalValue !== null &&
		currentDataPoint.goalValue > 0 &&
		datum.value >= currentDataPoint.goalValue;

	// Using some magic numbers here for x and y until we
	// find a better way to get size information about the bars
	return showCustomScatterPoint ? (
		<Sparkles x={x - GRAPHIC_X_OFFSET} y={y - GRAPHIC_Y_OFFSET} />
	) : null;
}

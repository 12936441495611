import {
	ITrackerAggregatedEntryDataItem,
	TrackerAggregatedEntriesInterval,
} from '@mobe/api/track/trackService';
import { format, isSameWeek, isSameYear } from 'date-fns';
import { VictoryAxisProps } from 'victory-native';

interface IFormData extends Omit<ITrackerAggregatedEntryDataItem, 'date'> {
	date: number | string;
}

export default function useXAxisProps(
	data: IFormData[],
	interval: TrackerAggregatedEntriesInterval
) {
	const props: VictoryAxisProps = {
		tickFormat: (value: Date) => format(value, 'M/d'),
		tickValues: data.map((item) => new Date(item.date)),
		fixLabelOverlap: true,
	};

	try {
		const startDate = new Date(data[0]?.date || Date.now());
		const endDate = new Date(data[data.length - 1]?.date || Date.now());

		if (isSameWeek(startDate, endDate)) {
			props.tickFormat = (value: Date) => format(value, 'EEEEEE');
		}

		if (isSameYear(startDate, endDate) && interval === TrackerAggregatedEntriesInterval.Month) {
			props.tickValues = data.map((item) => new Date(item.date));
			props.tickFormat = (value: Date) => format(value, 'MMMMM');
		}

		if (!isSameYear(startDate, endDate) && interval === TrackerAggregatedEntriesInterval.Month) {
			props.tickFormat = (value: Date) => format(value, "MMM ''yy"); // Adding year for context because this could be longer range
		}
	} catch (error) {
		console.warn('There was an error calculating x axis props: ', error);
	}

	return props;
}

/* eslint-disable react/prop-types */
import { useTrackerEntriesQuery } from '@mobe/api/track/trackApiHooks';
import { useGetTrackerIdFromAbbreviation } from '@mobe/api/track/trackApiUtils';
import { TrackerAbbreviation } from '@mobe/api/track/trackService';
import IconButton from '@mobe/components/iconButton/IconButton';
import Row from '@mobe/components/layout/Row';
import { Text } from '@mobe/components/text';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import useChartTheme from '@mobe/utils/styles/useChartTheme';
import {
	addDays,
	differenceInMinutes,
	endOfDay,
	format,
	isToday,
	setHours,
	subDays,
} from 'date-fns';
import startOfDay from 'date-fns/startOfDay';
import { max, min } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import {
	VictoryAxis,
	VictoryChart,
	VictoryClipContainer,
	VictoryLine,
	VictoryScatter,
} from 'victory-native';
import { TRACKER_BAR_CHART_MIN_HEIGHT } from './TrackerBarChart';

function roundDownToNearest20(value: number) {
	return Math.floor(value / 20) * 20;
}

function roundUpToNearest20(value: number) {
	return Math.ceil(value / 20) * 20;
}

export default function BloodGlucoseChart() {
	const { t } = useTranslation();
	const styleRules = useStyleRules();
	const getTrackerIdFromAbbreviation = useGetTrackerIdFromAbbreviation();
	const [selectedDay, setSelectedDay] = React.useState(new Date(Date.now()));
	const trackerEntriesQuery = useTrackerEntriesQuery({
		trackerTypeId: getTrackerIdFromAbbreviation(TrackerAbbreviation.BloodGlucose),
		startDate: startOfDay(selectedDay),
		endDate: endOfDay(selectedDay),
		page: 1,
		limit: 300,
	});

	const entries = trackerEntriesQuery.data?.data;

	// Mapping date strings to date ms numbers because it was fixing an issue with victory y axis breaking with date objects
	const data = React.useMemo(
		() =>
			entries?.map((dataPoint) => ({
				...dataPoint,
				date: new Date(dataPoint.activityDateTime).getTime(),
			})) || [],
		[entries]
	);

	// returns true if average interval between data points is under 50 minutes
	const hasDenseData =
		data.length > 1 &&
		differenceInMinutes(new Date(data[0].date), new Date(data[data.length - 1].date)) /
			(data.length - 1) <
			50;

	const highestValue = max(data.map((entry) => entry.value));
	const lowestValue = min(data.map((entry) => entry.value));

	const yMax = Math.max(roundUpToNearest20((highestValue || 0) + 5), 120);
	const yMin = Math.min(roundDownToNearest20((lowestValue || 60) - 5), 60);

	const yAxisOffsetWidth = 10 + Math.round(yMax).toString().length * 9;

	// Chart theme and common style props
	const chartTheme = useChartTheme();

	// Width for chart needs to be set for chart to have proper aspect ratio
	// https://formidable.com/open-source/victory/docs/common-props/#width
	const [chartContainerWidth, setChartContainerWidth] = React.useState<number | undefined>(
		undefined
	);

	return (
		<>
			<Row gutterSize={10} style={{ alignSelf: 'center' }}>
				<Row.Item>
					<IconButton
						accessibilityLabel={t('dateRangeSelect.prevRangeLabel', {
							rangeLabel: t('dateRangeSelect.itemLabels.day'),
						})}
						name="left"
						color="primary"
						size={16}
						onPress={() => setSelectedDay(subDays(selectedDay, 1))}
					/>
				</Row.Item>
				<Row.Item>
					<Text weight="semiBold">{format(selectedDay, 'MMMM d, yyyy')}</Text>
				</Row.Item>
				<Row.Item>
					<IconButton
						accessibilityLabel={t('dateRangeSelect.nextRangeLabel', {
							rangeLabel: t('dateRangeSelect.itemLabels.day'),
						})}
						name="right"
						disabled={isToday(selectedDay)}
						color={isToday(selectedDay) ? 'disabled' : 'primary'}
						size={16}
						onPress={() => setSelectedDay(addDays(selectedDay, 1))}
					/>
				</Row.Item>
			</Row>
			<View
				style={{ minHeight: TRACKER_BAR_CHART_MIN_HEIGHT, width: '100%' }}
				onLayout={(event) => setChartContainerWidth(event.nativeEvent.layout.width)}
			>
				{chartContainerWidth ? (
					<>
						<VictoryChart
							height={TRACKER_BAR_CHART_MIN_HEIGHT}
							width={chartContainerWidth}
							domain={{
								y: [yMin, yMax],
								x: [startOfDay(selectedDay), endOfDay(selectedDay)],
							}}
							maxDomain={{ y: yMax }}
							padding={{ top: 30, bottom: 40, left: 0, right: yAxisOffsetWidth }}
							domainPadding={{ x: [20, 20], y: [0, 20] }}
							theme={chartTheme}
						>
							{/* Y Axis */}
							<VictoryAxis
								dependentAxis
								orientation="right"
								crossAxis={false} // Force zero tick to show
								tickFormat={(value: number) => Math.round(value).toLocaleString()}
								tickCount={5}
							/>

							<VictoryLine
								data={data}
								x="date"
								y="value"
								groupComponent={<VictoryClipContainer />}
								style={{
									data: {
										stroke: '#71B8B7',
										strokeWidth: 2,
									},
								}}
							/>

							{!hasDenseData && (
								<VictoryScatter
									data={data}
									x="date"
									y="value"
									size={4}
									style={{
										data: {
											fill: styleRules.colors.cardBackground,
											stroke: '#71B8B7',
											strokeWidth: 2,
										},
									}}
								/>
							)}

							{/* X Axis */}
							<VictoryAxis
								fixLabelOverlap={true}
								tickFormat={(value: Date) => format(value, 'haaa')}
								tickValues={[
									setHours(selectedDay, 2),
									setHours(selectedDay, 6),
									setHours(selectedDay, 10),
									setHours(selectedDay, 14),
									setHours(selectedDay, 18),
									setHours(selectedDay, 22),
								]}
							/>
						</VictoryChart>
					</>
				) : null}
			</View>
		</>
	);
}

import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import * as React from 'react';
import { Platform, ScrollView, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import AutoKeyboardAvoidingView from '../autoKeyboardAvoidingView/AutoKeyboardAvoidingView';

export interface IScreenTemplateWithFooterProps {
	footer?: React.ReactNode;
	children: React.ReactNode;
	preFooter?: React.ReactNode;
	noPadding?: boolean;
	centered?: boolean;
	fullWidth?: boolean;
	fixedPositionFooter?: boolean;
	footerStyle?: StyleProp<ViewStyle>;
	scrollViewBackgroundColor?: string;
	scrollEnabled?: boolean;
}

function ScreenTemplateWithFooter(
	{
		footer = null,
		children,
		preFooter = null,
		noPadding = false,
		centered = false,
		fullWidth = false,
		fixedPositionFooter = false,
		footerStyle,
		scrollViewBackgroundColor,
		scrollEnabled = true,
	}: IScreenTemplateWithFooterProps,
	ref: any
) {
	const styles = useScreenTemplateWithFooterStyles({
		noPadding,
		centered,
		fullWidth,
		scrollViewBackgroundColor,
	});

	function renderFooters() {
		return (
			<View style={styles.footerContainer}>
				{preFooter && <View>{preFooter}</View>}
				{footer && (
					<View style={[styles.footer, footerStyle]} role="toolbar">
						<View style={styles.footerInner}>{footer}</View>
					</View>
				)}
			</View>
		);
	}

	return (
		<>
			<AutoKeyboardAvoidingView bottomPadding={20}>
				<ScrollView
					ref={ref}
					scrollEnabled={scrollEnabled}
					overScrollMode="never"
					bounces={false}
					bouncesZoom={false}
					style={styles.scrollView}
					contentContainerStyle={styles.scrollViewContent}
					centerContent={centered}
					keyboardShouldPersistTaps="handled"
				>
					<View style={styles.body}>{children}</View>
					{!fixedPositionFooter && renderFooters()}
				</ScrollView>
			</AutoKeyboardAvoidingView>
			{fixedPositionFooter && renderFooters()}
		</>
	);
}

export default React.forwardRef(ScreenTemplateWithFooter);

export function useScreenTemplateWithFooterStyles({
	noPadding = false,
	centered = false,
	fullWidth = false,
	scrollViewBackgroundColor,
}: {
	noPadding?: boolean;
	centered?: boolean;
	fullWidth?: boolean;
	scrollViewBackgroundColor?: string;
} = {}) {
	const rules = useStyleRules();
	const { bottom: bottomInset } = useSafeAreaInsets();

	return StyleSheet.create({
		scrollView: {
			backgroundColor:
				Platform.OS === 'web' ? rules.colors.background : rules.colors.cardBackground,
		},
		scrollViewContent: {
			flexGrow: centered ? 0 : 1,
			backgroundColor: centered ? rules.colors.cardBackground : undefined,

			...(centered &&
				Platform.OS !== 'ios' && {
					flexGrow: 1,
					justifyContent: 'center',
					alignItems: 'center',
				}),
		},
		body: {
			flexGrow: centered ? undefined : 1,
			backgroundColor: scrollViewBackgroundColor || rules.colors.cardBackground,
			paddingVertical: noPadding ? 0 : rules.spacing.appVerticalMargin,
			paddingHorizontal: noPadding ? 0 : rules.spacing.appHorizontalMargin,
			width: '100%',
			maxWidth: fullWidth ? undefined : rules.spacing.maxWidth,
			alignSelf: 'center',
		},
		footerContainer: {
			marginTop: 'auto',
		},
		footer: {
			paddingBottom: Math.max(bottomInset, 20),
			paddingTop: 12,
			paddingHorizontal: rules.spacing.appHorizontalMargin,
			borderTopColor: rules.colors.stroke,
			borderTopWidth: 1,
			backgroundColor: rules.colors.cardBackground,
		},
		footerInner: {
			width: '100%',
			maxWidth: rules.spacing.maxWidth,
			alignSelf: 'center',
		},
	});
}

import { useProfileQuery } from '@mobe/api/account/accountApiHooks';
import { useAllActivitiesQuery } from '@mobe/api/activities/activitiesApiHooks';
import { useAppointmentsQuery } from '@mobe/api/appointments/appointmentApiHooks';
import { useActionStepsQuery, useGoalsQuery } from '@mobe/api/goals/goalsApiHooks';
import { CoachType } from '@mobe/api/guides/guidesApi';
import { useGuideQuery } from '@mobe/api/guides/guidesApiHooks';
import DeferredLoadingIndicator from '@mobe/components/deferredLoadingIndicator/deferredLoadingIndicator';
import VrArray from '@mobe/components/layout/VrArray';
import { useAccessibilityFocus, useRestoreFocus } from '@mobe/utils/a11y';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useRefetchStaleQueryOnFocusEffect from '@mobe/utils/useRefetchStaleQueryOnFocusEffect';
import { useFocusEffect } from '@react-navigation/native';
import { noop } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutChangeEvent, Platform, ScrollView, View } from 'react-native';
import ActivitiesModule from '../activities/components/ActivitiesModule';
import BottomTabScreenTemplate from '../navigation/bottomTabs/components/BottomTabScreenTemplate';
import { PlanScreenNavigationProp, PlanScreenRouteProp } from '../navigation/bottomTabs/types';
import * as PlanAnalyticsEvents from '../plan/analyticsEvents';
import ActionStepsModule from './components/ActionStepsModule';
import GoalModule from './components/GoalModule';
import PlanHero, { PlanHeroVisualState } from './components/PlanHero';

enum ScreenModules {
	LTGoals = 'LTGoals',
	STGoals = 'STGoals',
	ActionSteps = 'actionSteps',
	Activities = 'activities',
}

export const PLAN_SCROLL_T0_MODULE_PARAM_KEY = 'scrollToModule';

export interface IPlanScreenParams {
	[PLAN_SCROLL_T0_MODULE_PARAM_KEY]?: `${ScreenModules}`;
}

interface IPlanScreenProps {
	route: PlanScreenRouteProp;
	navigation: PlanScreenNavigationProp;
}

export default function PlanScreen({ navigation, route }: IPlanScreenProps) {
	const { t } = useTranslation();
	const { wrapper } = useStyleHelpers();
	const scrollViewRef = React.useRef<ScrollView>(null);
	const { storeFocusTarget, hasStoredFocusTarget } = useRestoreFocus();

	const profileQuery = useProfileQuery();

	const [LTGoalLimit, setLTGoalLimit] = React.useState<number | undefined>(undefined);
	const [STGoalLimit, setSTGoalLimit] = React.useState<number | undefined>(undefined);
	const LTGoalsQuery = useGoalsQuery({
		type: 'LongTerm',
		status: 'InProgress',
		limit: LTGoalLimit,
	});
	const LTGoalsCompleteQuery = useGoalsQuery({ type: 'LongTerm', status: 'Completed' });
	const STGoalsQuery = useGoalsQuery({
		type: 'ShortTerm',
		status: 'InProgress',
		limit: STGoalLimit,
	});
	const STGoalsCompleteQuery = useGoalsQuery({ type: 'ShortTerm', status: 'Completed' });

	const [actionStepsLimit, setActionStepsLimit] = React.useState<number | undefined>(undefined);
	const actionStepsQuery = useActionStepsQuery({ status: 'InProgress', limit: actionStepsLimit });
	const completedActionStepsQuery = useActionStepsQuery({ status: 'Completed' });

	const activitiesQuery = useAllActivitiesQuery();

	useFocusEffect(
		React.useCallback(() => {
			setLTGoalLimit(undefined);
			setSTGoalLimit(undefined);
			setActionStepsLimit(undefined);
		}, [])
	);

	useRefetchStaleQueryOnFocusEffect([
		LTGoalsQuery,
		STGoalsQuery,
		actionStepsQuery,
		activitiesQuery,

		// If these queries have been fetched and return results, there's no need to refetch on this screen
		...(!LTGoalsCompleteQuery.data?.goals.length ? [LTGoalsCompleteQuery] : []),
		...(!STGoalsCompleteQuery.data?.goals.length ? [STGoalsCompleteQuery] : []),
		...(!completedActionStepsQuery.data?.actionSteps.length ? [completedActionStepsQuery] : []),
	]);

	const guideQuery = useGuideQuery();
	const hasGuide = guideQuery.isSuccess && Boolean(guideQuery.data);

	const appointmentsQuery = useAppointmentsQuery();
	const guideAppointment = appointmentsQuery.data?.allAppointments.find(
		(appt) => appt.coachType !== CoachType.Pharmacist
	);
	const hasCallWithGuide = Boolean(guideAppointment);
	const hasVisionData = Boolean(
		profileQuery.data?.visionStatement || profileQuery.data?.visionImageUrl
	);

	const heroState = React.useMemo<PlanHeroVisualState | undefined>(() => {
		if (!hasGuide) return 'noGuide';
		if (hasVisionData) return 'hasData';
		// Must wait for profileQuery to load here otherwise the component could quickly flash between states
		if (profileQuery.isLoading) return undefined;
		if (hasCallWithGuide) return 'hasCall';
		if (!hasCallWithGuide) return 'hasGuideNoCall';
	}, [hasGuide, hasCallWithGuide, hasVisionData, profileQuery.isLoading]);

	const [LTGoalRef, focusLTGoal] = useAccessibilityFocus();
	const [STGoalRef, focusSTGoal] = useAccessibilityFocus();
	const [actionStepsRef, focusActionSteps] = useAccessibilityFocus();
	const [activitiesRef, focusActivities] = useAccessibilityFocus();

	const [LTGoalsY, setLTGoalsY] = React.useState(0);
	const [STGoalsY, setSTGoalsY] = React.useState(0);
	const [actionStepsY, setActionStepsY] = React.useState(0);
	const [activitiesY, setActivitiesY] = React.useState(0);

	function scrollTo(y: number) {
		if (!y) return;
		scrollViewRef.current?.scrollTo({ y, animated: false });
		navigation.setParams({ scrollToModule: undefined });
	}

	React.useEffect(() => {
		if (Platform.OS === 'web') return;
		switch (route.params?.scrollToModule) {
			case 'LTGoals':
				scrollTo(LTGoalsY);
				focusLTGoal();
				break;
			case 'STGoals':
				scrollTo(STGoalsY);
				focusSTGoal();
				break;
			case 'actionSteps':
				scrollTo(actionStepsY);
				focusActionSteps();
				break;
			case 'activities':
				scrollTo(activitiesY);
				focusActivities();
				break;
			default:
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [LTGoalsY, STGoalsY, actionStepsY, activitiesY, route.params?.scrollToModule]);

	function handleLayout(
		event: LayoutChangeEvent,
		setState: React.Dispatch<React.SetStateAction<number>>,
		moduleId: ScreenModules,
		webFocus: () => void
	) {
		if (scrollViewRef.current && Platform.OS !== 'web') {
			// TODO: Kevin fix this ts warning/error
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			event.target.measureLayout(scrollViewRef.current as any, (x, y) => setState(y), noop);
		}

		if (Platform.OS === 'web' && route.params?.scrollToModule === moduleId) {
			try {
				const anchor = document.getElementById(moduleId);
				if (!anchor) return;
				webFocus();
				anchor.scrollIntoView();
			} catch (error) {
				console.warn(error);
			}
		}
	}

	return (
		<BottomTabScreenTemplate screenTitle={t('plan.screenTitle')}>
			<ScrollView ref={scrollViewRef}>
				<DeferredLoadingIndicator isLoading={heroState === undefined}>
					{heroState !== undefined && (
						<PlanHero
							visualState={heroState}
							appointment={guideAppointment}
							visionStatement={profileQuery.data?.visionStatement || ''}
							accessibilityAutoFocus={
								!hasStoredFocusTarget && route.params?.scrollToModule === undefined
							}
						/>
					)}
				</DeferredLoadingIndicator>
				<View style={wrapper}>
					<DeferredLoadingIndicator
						isLoading={
							LTGoalsQuery.isLoading ||
							LTGoalsCompleteQuery.isLoading ||
							STGoalsQuery.isLoading ||
							STGoalsCompleteQuery.isLoading
						}
						deferDuration={3000}
					>
						<VrArray increment={10}>
							{LTGoalsQuery.isSuccess && LTGoalsCompleteQuery.isSuccess && (
								<View
									id={ScreenModules.LTGoals}
									onLayout={(event) =>
										handleLayout(event, setLTGoalsY, ScreenModules.LTGoals, focusLTGoal)
									}
								>
									<View ref={LTGoalRef} focusable accessible />
									<GoalModule
										goals={LTGoalsQuery.data.goals}
										hasCompletedGoals={LTGoalsCompleteQuery.data.pagination.total > 0}
										type="LongTerm"
										onShowMorePress={() => setLTGoalLimit(LTGoalsQuery.data.pagination.total)}
										onCardPress={storeFocusTarget}
										total={LTGoalsQuery.data.pagination.total}
									/>
								</View>
							)}

							{STGoalsQuery.isSuccess && STGoalsCompleteQuery.isSuccess && (
								<View
									id={ScreenModules.STGoals}
									onLayout={(event) =>
										handleLayout(event, setSTGoalsY, ScreenModules.STGoals, focusSTGoal)
									}
								>
									<View ref={STGoalRef} focusable accessible />
									<GoalModule
										goals={STGoalsQuery.data.goals}
										hasCompletedGoals={STGoalsCompleteQuery.data.pagination.total > 0}
										type="ShortTerm"
										onShowMorePress={() => setSTGoalLimit(STGoalsQuery.data.pagination.total)}
										onCardPress={storeFocusTarget}
										total={STGoalsQuery.data.pagination.total}
									/>
								</View>
							)}

							{actionStepsQuery.isSuccess && completedActionStepsQuery.isSuccess && (
								<View
									id={ScreenModules.ActionSteps}
									onLayout={(event) =>
										handleLayout(
											event,
											setActionStepsY,
											ScreenModules.ActionSteps,
											focusActionSteps
										)
									}
								>
									<View ref={actionStepsRef} focusable accessible />
									<ActionStepsModule
										actionSteps={actionStepsQuery.data.actionSteps}
										hasCompletedActionSteps={completedActionStepsQuery.data.pagination.total > 0}
										onShowMorePress={() =>
											setActionStepsLimit(actionStepsQuery.data.pagination.total)
										}
										total={actionStepsQuery.data.pagination.total}
										onComplete={PlanAnalyticsEvents.actionStepCompletePressPlan}
										onCardPress={storeFocusTarget}
									/>
								</View>
							)}

							<View
								id={ScreenModules.Activities}
								onLayout={(event) =>
									handleLayout(event, setActivitiesY, ScreenModules.Activities, focusActivities)
								}
							>
								<View ref={activitiesRef} focusable accessible />
								<ActivitiesModule onCardPress={storeFocusTarget} />
							</View>
						</VrArray>
					</DeferredLoadingIndicator>
				</View>
			</ScrollView>
		</BottomTabScreenTemplate>
	);
}

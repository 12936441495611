import { useTrackersQuery } from '@mobe/api/track/trackApiHooks';
import { TrackerAbbreviation } from '@mobe/api/track/trackService';
import { Text } from '@mobe/components/text';
import { format } from 'date-fns';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IDefaultTrackerTableProps } from './DefaultTrackerTable';
import TrackerTable from './TrackerTable';

export default function BloodGlucoseTable({
	entries,
	unit,
	...trackerTableProps
}: IDefaultTrackerTableProps) {
	const { t } = useTranslation();
	const trackersQuery = useTrackersQuery();
	const units =
		trackersQuery.data?.find(
			(tracker) => tracker.trackerAbbreviation === TrackerAbbreviation.BloodGlucose
		)?.units || [];

	return (
		<TrackerTable
			{...trackerTableProps}
			columnStyles={[{ width: '27.5%' }, { width: '17.5%' }, { width: '55%' }]}
			tableColumnHeadings={[
				t('track.detail.dateTableHeading'),
				unit,
				t('trackers.bloodGlucose.measurementDetails'),
			]}
			tableRows={entries?.map((entry) => {
				const date = format(new Date(entry.activityDateTime), 'LL/dd/yy');
				const unitLabel = units.find((unit) => unit.id === entry.unitId)?.description;

				return {
					entry,
					columns: [
						<Text key={`${entry.consumerTrackerId}-1`}>{date}</Text>,
						<Text key={`${entry.consumerTrackerId}-2`}>{entry.value.toLocaleString()}</Text>,
						<Text key={`${entry.consumerTrackerId}-3`}>{unitLabel}</Text>,
					],
				};
			})}
		/>
	);
}

import { yupResolver } from '@hookform/resolvers/yup';
import { useControlledCalendarInputProps } from '@mobe/components/input';
import InputDate from '@mobe/components/input/InputDate';
import MoodPicker from '@mobe/components/moodPicker/MoodPicker';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import * as React from 'react';
import { useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import * as Yup from 'yup';
import { ITrackerForm, IUseTrackerForm } from '../../useTrackerContent';

export default function useMoodForm({ tracker, trackerEntry }: IUseTrackerForm): ITrackerForm {
	const { t } = useTranslation();
	const { vr } = useStyleHelpers();

	let trackerDefaultValue = null;
	const trackerSchema: Yup.AnySchema = Yup.number()
		.min(1)
		.max(5)
		.required()
		.nullable()
		.label(tracker.inputLabel);

	if (trackerEntry) {
		trackerDefaultValue = trackerEntry.value;
	}

	const validationSchema = Yup.object({
		date: Yup.date().required(),
		tracker: trackerSchema,
	});

	const form = useForm<Yup.InferType<typeof validationSchema>>({
		mode: 'all',
		resolver: yupResolver(validationSchema),
		defaultValues: {
			date: trackerEntry ? new Date(trackerEntry.activityDateTime) : new Date(Date.now()),
			tracker: trackerDefaultValue,
		},
	});

	const dateInputProps = useControlledCalendarInputProps({
		name: 'date',
		control: form.control,
	});

	const trackerMoodProps = useController({
		name: 'tracker',
		control: form.control,
	});

	function renderForm() {
		return (
			<>
				<View style={vr(2)}>
					<InputDate
						label={t('track.entry.dateLabel')}
						popupLabel={t('track.entry.calendarTitle')}
						errorMessage={form.formState.errors.date?.message}
						{...dateInputProps}
					/>
				</View>
				<MoodPicker
					value={trackerMoodProps.field.value}
					onSelect={trackerMoodProps.field.onChange}
					label={tracker.inputLabel}
				/>
			</>
		);
	}

	return { form, renderForm };
}

import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import DateTimePicker, { DateTimePickerAndroid } from '@react-native-community/datetimepicker';
import { format } from 'date-fns';
import { noop } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, Pressable, StyleSheet, View } from 'react-native';
import Blocks from '../layout/Blocks';
import { Text } from '../text';
import BaseInputLabel from './BaseInputLabel';
import useBaseInputStyles from './baseInputStyles';
import InputCalendar from './InputCalendar';
import { IInputTextProps } from './InputText';

interface IInputCalendarProps extends Omit<IInputTextProps, 'value'> {
	value: Date;
	onChangeDate: (date: Date) => void;
	popupLabel?: string;
}

function InputDateTime({ value, onChangeDate }: IInputCalendarProps, ref: any) {
	const { t } = useTranslation();
	const styleRules = useStyleRules();
	const { vr } = useStyleHelpers();
	const baseInputStyles = useBaseInputStyles();

	function handleAndroidCalendarOpenPress() {
		if (Platform.OS === 'android') {
			DateTimePickerAndroid.open({
				value,
				mode: 'date',
				maximumDate: new Date(Date.now()),
				onChange: (event, date) => (date ? onChangeDate(date) : noop()),
			});
		}
	}

	function handleAndroidClockOpenPress() {
		if (Platform.OS === 'android') {
			DateTimePickerAndroid.open({
				value,
				mode: 'time',
				onChange: (event, date) => (date ? onChangeDate(date) : noop()),
			});
		}
	}

	if (Platform.OS === 'ios') {
		return (
			<View style={styles.inputRow}>
				<View style={styles.inputLabel}>
					<Text weight="semiBold">{t('forms.date.label')}</Text>
				</View>
				<View style={styles.inputBody}>
					<DateTimePicker
						value={value}
						maximumDate={new Date(Date.now())}
						mode="date"
						display="calendar"
						onChange={(event, date) => (date ? onChangeDate(date) : noop())}
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						themeVariant={styleRules.settings.colorScheme}
					/>
					<DateTimePicker
						value={value}
						maximumDate={new Date(Date.now())}
						mode="time"
						onChange={(event, date) => (date ? onChangeDate(date) : noop())}
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						themeVariant={styleRules.settings.colorScheme}
					/>
				</View>
			</View>
		);
	}

	if (Platform.OS === 'android') {
		return (
			<View style={styles.inputRow}>
				<View style={styles.inputLabel}>
					<Text weight="semiBold">{t('forms.date.label')}</Text>
				</View>
				<View style={styles.inputBody}>
					<Pressable
						ref={ref}
						onPress={handleAndroidCalendarOpenPress}
						style={[baseInputStyles.inputWrapper, { paddingHorizontal: 12 }]}
						aria-label={t('forms.date.accessibilityLabel', { date: format(value, 'PP') })}
					>
						<Text>{format(value, 'PP')}</Text>
					</Pressable>
					<Pressable
						onPress={handleAndroidClockOpenPress}
						style={[baseInputStyles.inputWrapper, { paddingHorizontal: 12 }]}
						aria-label={t('forms.time.accessibilityLabel', { time: format(value, 'p') })}
					>
						<Text>{format(value, 'p')}</Text>
					</Pressable>
				</View>
			</View>
		);
	}

	if (Platform.OS === 'web') {
		return (
			<Blocks>
				<InputCalendar
					ref={ref}
					label={t('track.entry.dateLabel')}
					popupLabel={t('track.entry.calendarTitle')}
					value={value}
					onChangeDate={onChangeDate}
					preserveTime
				/>
				<View>
					<BaseInputLabel style={vr(1)}>{t('forms.time.label')}</BaseInputLabel>
					<View style={baseInputStyles.inputWrapper}>
						<input
							style={{
								...baseInputStyles.input,
								...{ border: 'none', paddingLeft: 12, paddingRight: 4, background: 'transparent' },
							}}
							type="time"
							value={format(value, 'HH:mm')}
							onChange={(event) => {
								event.target.valueAsDate !== null &&
									onChangeDate(new Date(`${format(value, 'yyyy-MM-dd')}T${event.target.value}`));
							}}
						/>
					</View>
				</View>
			</Blocks>
		);
	}

	return null;
}

const styles = StyleSheet.create({
	inputRow: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		alignItems: 'center',
		marginBottom: 35,
	},
	inputLabel: {
		marginRight: 'auto',
	},
	inputBody: {
		flexDirection: 'row',
		marginLeft: Platform.OS === 'ios' ? -10 : 0,
		columnGap: Platform.OS === 'android' ? 10 : 0,
	},
});

export default React.forwardRef(InputDateTime);

import { TrackerAggregatedEntriesInterval } from '@mobe/api/track/trackService';
import useChartTheme from '@mobe/utils/styles/useChartTheme';
import { max, min } from 'lodash';
import * as React from 'react';
import { View } from 'react-native';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryScatter } from 'victory-native';
import { ITrackerChartProps, ITrackerContent } from '../../useTrackerContent';
import { bloodPressureNumberToObject } from '../bloodPressureUtils';
import BloodPressureLegend from './BloodPressureLegend';
import { TRACKER_BAR_CHART_MIN_HEIGHT } from './TrackerBarChart';
import useXAxisProps from './useXAxisProps';

function roundDownToNearest20(value: number) {
	return Math.floor(value / 20) * 20;
}

function roundUpToNearest20(value: number) {
	return Math.ceil(value / 20) * 20;
}

export interface IBloodPressureChartProps extends ITrackerChartProps {
	tracker: ITrackerContent;
}

export default function BloodPressureChart({
	tracker,
	rawData = [],
	interval = TrackerAggregatedEntriesInterval.Day,
}: IBloodPressureChartProps) {
	const systolicData = rawData
		.filter((datum) => datum.maxValue)
		.map((datum) => {
			return {
				x: new Date(datum.date).getTime(),
				y0: bloodPressureNumberToObject(datum.minValue || 0).systolic,
				y: bloodPressureNumberToObject(datum.maxValue || 0).systolic,
			};
		});
	const diastolicData = rawData
		.filter((datum) => datum.maxValue)
		.map((datum) => {
			return {
				x: new Date(datum.date).getTime(),
				y0: bloodPressureNumberToObject(datum.minValue || 0).diastolic,
				y: bloodPressureNumberToObject(datum.maxValue || 0).diastolic,
			};
		});
	const chartTheme = useChartTheme();
	const xAxisProps = useXAxisProps(rawData, interval);
	const highestValue = max([
		...systolicData.map((entry) => entry.y),
		...diastolicData.map((entry) => entry.y),
	]);
	const lowestValue = min([
		...systolicData.map((entry) => entry.y0),
		...diastolicData.map((entry) => entry.y0),
	]);
	const yMax = Math.max(roundUpToNearest20(highestValue || 0), tracker.minChartScale);
	const yMin = Math.min(roundDownToNearest20(lowestValue || 60), 60);
	const yAxisOffsetWidth = 10 + Math.round(yMax).toString().length * 9;

	// Width for chart needs to be set for chart to have proper aspect ratio
	// https://formidable.com/open-source/victory/docs/common-props/#width
	const [chartContainerWidth, setChartContainerWidth] = React.useState<number | undefined>(
		undefined
	);

	const barWidth = rawData.length > 12 ? 6 : 10;

	return (
		<>
			<View
				style={{ minHeight: TRACKER_BAR_CHART_MIN_HEIGHT, width: '100%' }}
				onLayout={(event) => setChartContainerWidth(event.nativeEvent.layout.width)}
			>
				{chartContainerWidth ? (
					<VictoryChart
						height={TRACKER_BAR_CHART_MIN_HEIGHT}
						width={chartContainerWidth}
						domain={{ y: [yMin, yMax] }}
						maxDomain={{ y: yMax }}
						padding={{ top: 30, bottom: 40, left: 0, right: yAxisOffsetWidth }}
						domainPadding={{ x: [20, 20], y: [0, 20] }}
						theme={chartTheme}
					>
						<VictoryAxis dependentAxis orientation="right" />
						<VictoryAxis {...xAxisProps} />
						<VictoryBar
							data={systolicData}
							barWidth={barWidth}
							style={{
								data: {
									fill: '#63B3E177',
									strokeWidth: 0,
								},
							}}
						/>
						<VictoryScatter
							data={systolicData}
							size={barWidth / 2}
							style={{ data: { fill: '#63B3E1' } }}
						/>
						<VictoryScatter
							data={systolicData.map((datum) => ({
								x: datum.x,
								y: datum.y0,
							}))}
							size={barWidth / 2}
							style={{ data: { fill: '#63B3E1' } }}
						/>
						<VictoryBar
							data={diastolicData}
							barWidth={barWidth}
							style={{
								data: {
									fill: '#F6C46377',
									strokeWidth: 0,
								},
							}}
						/>
						<VictoryScatter
							data={diastolicData}
							size={barWidth / 2}
							style={{ data: { fill: '#F6C463' } }}
						/>
						<VictoryScatter
							data={diastolicData.map((datum) => ({
								x: datum.x,
								y: datum.y0,
							}))}
							size={barWidth / 2}
							style={{ data: { fill: '#F6C463' } }}
						/>
					</VictoryChart>
				) : null}
			</View>

			<BloodPressureLegend />
		</>
	);
}

import Button from '@mobe/components/button/Button';
import CardButton from '@mobe/components/cardButton/CardButton';
import Dismissible from '@mobe/components/dismissible/Dismissible';
import { ConnectHealthDataGraphic } from '@mobe/components/graphics';
import Heading from '@mobe/components/heading/Heading';
import { Text } from '@mobe/components/text';
import { useHasDismissedTrackerCTAFromHome } from '@mobe/utils/storage/appStateStorage';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { HomeScreenNavigationProp } from '../../navigation/bottomTabs/types';
import * as HomeAnalyticsEvents from '../analyticsEvents';

export default function ConnectHealthDataCta({ style }: { style?: StyleProp<ViewStyle> }) {
	const navigation = useNavigation<HomeScreenNavigationProp>();
	const [, setHasDismissedTrackerCTAFromHome] = useHasDismissedTrackerCTAFromHome();
	const { vr } = useStyleHelpers();
	const { t } = useTranslation();
	const styles = useStyles();

	function handleDismiss() {
		HomeAnalyticsEvents.connectHealthCtaDismiss();
		setHasDismissedTrackerCTAFromHome(true);
	}

	function handlePress() {
		HomeAnalyticsEvents.connectHealthCtaPress();
		navigation.navigate('CONNECT_HEALTH_DATA_SCREEN');
	}

	return (
		<Dismissible onAnimationComplete={handleDismiss}>
			{(dismiss) => (
				<CardButton
					style={[styles.card, style]}
					onDismissPress={dismiss}
					onPress={handlePress}
					noPaddingWeb
				>
					<Heading level="h3" style={[styles.heading, vr(2)]}>
						{t('home.connectHealthDataCtaHeading')}
					</Heading>
					<View style={styles.row}>
						<View style={styles.body}>
							<Text style={vr(5)}>{t('home.connectHealthDataCtaBody')}</Text>
							<Button
								title={t('home.connectHealthDataCtaButton')}
								size="sm"
								onPress={handlePress}
							/>
						</View>
						<View style={styles.graphic}>
							<ConnectHealthDataGraphic />
						</View>
					</View>
				</CardButton>
			)}
		</Dismissible>
	);
}

function useStyles() {
	const rules = useStyleRules();
	return StyleSheet.create({
		card: {
			width: '100%',
			maxWidth: 520,
			alignSelf: 'center',
			paddingTop: '5%',
			paddingLeft: 4,
		},
		row: {
			flexDirection: 'row',
		},
		heading: {
			width: '60%',
			maxWidth: 300,
		},
		body: {
			width: '60%',
			maxWidth: 300,
			paddingBottom: '5%',
			zIndex: 1,
		},
		graphic: {
			width: '50%',
			maxWidth: 200,
			position: 'absolute',
			right: -rules.spacing.appHorizontalMargin,
			bottom: -20,
		},
		closeButton: {
			width: 30,
			height: 30,
			aspectRatio: 1,
			borderRadius: 15,
			backgroundColor: rules.colors.strokeLight,
			alignItems: 'center',
			justifyContent: 'center',
			marginBottom: 12,
			marginLeft: -rules.spacing.appHorizontalMargin / 2,
		},
	});
}

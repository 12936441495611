import { ITrackerDetail, TrackerSource } from '@mobe/api/track/trackService';
import { Text } from '@mobe/components/text';
import { format } from 'date-fns';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import useTrackerDisplayUtils from '../useTrackerDisplayUtils';
import TrackerTable, { ITrackerTableProps } from './TrackerTable';

export interface IDefaultTrackerTableProps extends Partial<ITrackerTableProps> {
	entries?: ITrackerDetail[];
	unit: string;
	valueTransform?: (value: number) => string;
}

export default function DefaultTrackerTable({
	entries,
	unit,
	valueTransform,
	...trackerTableProps
}: IDefaultTrackerTableProps) {
	const { t } = useTranslation();
	const trackerDisplayUtils = useTrackerDisplayUtils();
	const transformValue = valueTransform || ((value: number) => value.toLocaleString());

	return (
		<TrackerTable
			{...trackerTableProps}
			tableColumnHeadings={[
				t('track.detail.dateTableHeading'),
				unit,
				t('track.detail.sourceTableHeading'),
			]}
			tableRows={entries?.map((entry) => {
				// In case we want different formatting for different date ranges
				// const date =
				// 	dateRange === 'week'
				// 		? format(new Date(entry.activityDateTime), 'E LL/dd/yy')
				// 		: format(new Date(entry.activityDateTime), 'LL/dd/yy');
				const date = format(new Date(entry.activityDateTime), 'LL/dd/yy');

				let sourceContent;
				switch (entry.source) {
					case TrackerSource.Manual:
						sourceContent = t('track.detail.sources.manual');
						break;
					case TrackerSource.Guide:
						sourceContent = t('track.detail.sources.guide');
						break;
					default:
						sourceContent = trackerDisplayUtils.getTrackerName(entry.source);
						break;
				}

				return {
					entry,
					columns: [
						<Text key={`${entry.consumerTrackerId}-1`}>{date}</Text>,
						<Text key={`${entry.consumerTrackerId}-2`}>{transformValue(entry.value)}</Text>,
						<Text key={`${entry.consumerTrackerId}-3`}>{sourceContent}</Text>,
					],
				};
			})}
		/>
	);
}

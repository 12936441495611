import { useAccessibilityInfo } from '@mobe/utils/useAccessibilityInfo';
import { Platform } from 'react-native';
import { ITrackerForm, IUseTrackerForm } from '../../useTrackerContent';
import useBloodPressureFormTextInput from './useBloodPressureFormTextInput';
import useBloodPressureFormWheelInput from './useBloodPressureWheelInput';

export default function useBloodPressureForm(props: IUseTrackerForm): ITrackerForm {
	const accessibilityInfo = useAccessibilityInfo();
	const wheelInputForm = useBloodPressureFormWheelInput(props);
	const textInputForm = useBloodPressureFormTextInput(props);

	if (Platform.OS === 'web' || accessibilityInfo.screenReaderEnabled) {
		return textInputForm;
	}

	return wheelInputForm;
}

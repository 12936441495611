import {
	useTrackersAddEntryMutation,
	useTrackersDeleteEntryMutation,
	useTrackersUpdateEntryMutation,
} from '@mobe/api/track/trackApiHooks';
import { useGetTrackerIdFromAbbreviation } from '@mobe/api/track/trackApiUtils';
import { TrackerSource } from '@mobe/api/track/trackService';
import useTrackEntryToast from '@mobe/components/toast/useTrackEntryToast';
import { useAlert } from '@mobe/utils/useAlert';
import useGenericErrorAlert from '@mobe/utils/useGenericErrorAlert';
import { isAfter } from 'date-fns';
import { noop } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import useTrackerContent from '../useTrackerContent';
import { ITrackEntryScreenProps } from './TrackEntryScreen';

interface IFormData {
	date: Date;
	tracker: string;
	unit?: string;
}

export default function useTrackEntryScreenController({
	navigation,
	route,
}: ITrackEntryScreenProps) {
	const trackersUpdateEntryMutation = useTrackersUpdateEntryMutation();
	const trackersAddEntryMutation = useTrackersAddEntryMutation();
	const trackersDeleteEntryMutation = useTrackersDeleteEntryMutation();
	const trackerContent = useTrackerContent()[route.params.trackerAbbreviation];
	const getTrackerIdFromAbbreviation = useGetTrackerIdFromAbbreviation();
	const trackerTypeId = getTrackerIdFromAbbreviation(route.params.trackerAbbreviation);
	const auxiliaryForm = trackerContent.useAuxiliaryForm
		? trackerContent.useAuxiliaryForm()
		: undefined;
	const { t } = useTranslation();
	const genericErrorAlert = useGenericErrorAlert();
	const { form, renderForm } = trackerContent.useForm({
		tracker: trackerContent,
		trackerEntry: route.params.trackerEntry,
		onKeyboardSubmit: handleSubmit,
	});
	const { mobeAlert } = useAlert();
	const newEntryToast = useTrackEntryToast();

	const [isUpdatingNonManualTracker, setIsUpdatingNonManualTracker] = React.useState(false);

	// For unbinding navigation listener after form submit
	const [formSubmitted, setFormSubmitted] = React.useState(false);

	const shouldAlertBeforeExit = form.formState.isDirty && form.formState.isValid && !formSubmitted;

	// Display a confirmation alert if screen is popped before submission with a dirty form
	React.useEffect(() => {
		if (!shouldAlertBeforeExit) {
			return;
		}

		const removeListener = navigation.addListener('beforeRemove', (event) => {
			event.preventDefault();

			mobeAlert(t('track.entry.exitAlertTitle'), '', [
				{
					text: t('track.entry.exitAlertDiscard'),
					style: 'destructive',
					onPress: () => navigation.dispatch(event.data.action),
				},
				{
					text: t('track.entry.exitAlertSave'),
					onPress: handleSubmit,
				},
			]);
		});

		return removeListener;
	}, [navigation, shouldAlertBeforeExit]);

	// Pop screen after form is submitted
	React.useEffect(() => {
		if (formSubmitted) {
			navigation.pop();
		}
	}, [formSubmitted, navigation]);

	/**
	 * Adds entry via trackersAddEntryMutation.
	 */
	function addEntry(formData: IFormData) {
		auxiliaryForm?.handleSubmit();

		return trackersAddEntryMutation.mutateAsync({
			activityDateTime: formData.date.toISOString(),
			value: formData.tracker,
			unitId: Number(formData.unit) || null,
			trackerTypeId,
		});
	}

	/**
	 * Updates entry via trackersUpdateEntryMutation.
	 */
	function updateEntry(trackerId: number, formData: IFormData) {
		return trackersUpdateEntryMutation.mutateAsync({
			trackerEntry: {
				activityDateTime: formData.date.toISOString(),
				value: formData.tracker,
				unitId: Number(formData.unit) || null,
				trackerTypeId,
			},
			consumerTrackerId: trackerId,
		});
	}

	/**
	 * Deletes entry via trackersDeleteEntryMutation.
	 */
	function deleteEntry(trackerId: number) {
		return trackersDeleteEntryMutation.mutateAsync({
			consumerTrackerId: trackerId,
		});
	}

	function showSuccessToast() {
		if (!route.params.displaySuccessToast) return;
		newEntryToast();
	}

	/**
	 * Generic error alert which pops screen on confirmation.
	 */
	function handleError() {
		genericErrorAlert(() => setFormSubmitted(true));
	}

	/**
	 * Handler for adding and updating entries. If entry ID exists as a route param, it updates.
	 */
	function handleSubmit() {
		form.handleSubmit(async (rawFormData) => {
			const currentTrackerEntry = route.params.trackerEntry;
			const formData = {
				...rawFormData,
				// Prevent selection of future time
				date: isAfter(rawFormData.date, Date.now()) ? new Date(Date.now()) : rawFormData.date,
			};

			try {
				if (currentTrackerEntry) {
					if (currentTrackerEntry.source === TrackerSource.Manual) {
						// Manual entry, update entry
						await updateEntry(currentTrackerEntry.consumerTrackerId, formData);
					} else {
						setIsUpdatingNonManualTracker(true);
						// Delete entry, and add a new manual entry
						await deleteEntry(currentTrackerEntry.consumerTrackerId);
						await addEntry(formData);
					}
				} else {
					// New entry
					await addEntry(formData);
					showSuccessToast();
				}

				setFormSubmitted(true);
				setIsUpdatingNonManualTracker(false);
			} catch (error) {
				handleError();
			}
		})();
	}

	/**
	 * Handler for deleting an entry. Confirms deletion with a native alert dialog.
	 */
	function handleDelete() {
		mobeAlert(t('track.updateEntry.deleteEntryAlertTitle'), '', [
			{
				text: t('track.updateEntry.deleteEntryAlertCancel'),
				style: 'cancel',
				onPress: noop,
			},
			{
				text: t('track.updateEntry.deleteEntryAlertConfirm'),
				style: 'destructive',
				onPress: async () => {
					const currentTrackerEntry = route.params.trackerEntry;

					if (!currentTrackerEntry) {
						handleError();
						return;
					}

					try {
						await deleteEntry(currentTrackerEntry.consumerTrackerId);
						setFormSubmitted(true);
					} catch (error) {
						handleError();
					}
				},
			},
		]);
	}

	return {
		trackerContent,
		form,
		handleSubmit,
		handleDelete,
		renderForm,
		auxiliaryForm,
		trackersUpdateEntryMutation,
		trackersAddEntryMutation,
		trackersDeleteEntryMutation,
		isUpdatingNonManualTracker,
	};
}

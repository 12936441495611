import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

export default function TrackerBloodPressureGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(388, 388);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					fill="#cb1e00"
					d="M94.719 107.032a5.797 5.797 0 0 1 4.302-2.852c.039-.004.078-.008.121-.016.126-.011.255-.023.38-.027.083 0 .169-.004.251-.004.082 0 .168 0 .251.004.125.004.254.016.38.027.039.004.078.008.121.016a5.797 5.797 0 0 1 4.302 2.852l61.393 106.336 39.646-68.669a5.802 5.802 0 0 1 7.04-2.539 5.79 5.79 0 0 1 3.283 2.629l15.868 26.779c18.402-16.467 41.909-25.474 66.8-25.474 6.48 0 12.87.615 19.095 1.81 1.301-5.956 2.006-11.832 2.006-17.596-.007-44.17-35.817-79.979-79.987-79.979-35.112 0-79.984 27.507-79.984 60.472 0-32.965-45.451-60.472-79.98-60.472-44.174 0-79.984 35.809-79.984 79.983 0 15.836 5.254 32.55 13.858 49.303a5.68 5.68 0 0 1 .455-.023h38.493l41.89-72.56Z"
				/>
				<Path
					fill="#cb1e00"
					d="M198.529 246.419c0-24.008 8.381-46.724 23.751-64.821l-11.221-18.935-39.097 67.717a5.782 5.782 0 0 1-2.719 3.584 5.809 5.809 0 0 1-7.934-2.127L99.771 125.251 61.337 191.82a5.803 5.803 0 0 1-4.102 2.828 5.819 5.819 0 0 1-1.818.294H20.352c43.073 71.717 139.637 140.602 139.637 140.602s22.614-16.134 50.839-40.911c-8.028-14.61-12.303-31.081-12.303-48.214h.004Z"
				/>
				<Path
					fill="#cacaca"
					d="M299.318 157.344c-48.947 0-88.623 40.428-88.623 90.295 0 49.867 39.679 90.294 88.623 90.294 48.943 0 88.622-40.427 88.622-90.294s-39.679-90.295-88.622-90.295Zm0 166.026c-41.051 0-74.329-33.906-74.329-75.731 0-41.825 33.278-75.731 74.329-75.731 41.05 0 74.328 33.906 74.328 75.731 0 41.825-33.278 75.731-74.328 75.731Z"
				/>
				<Path
					fill="#cacaca"
					d="M216.821 250.984h17.847c3.383 0 6.126-2.246 6.126-5.017 0-2.77-2.743-5.016-6.126-5.016h-17.847c-3.383 0-6.126 2.246-6.126 5.016 0 2.771 2.743 5.017 6.126 5.017ZM303.767 249.362l29.064-29.064a5.535 5.535 0 0 0-7.826-7.825l-29.064 29.064a5.533 5.533 0 1 0 7.826 7.825ZM304.353 328.464v-17.847c0-3.383-2.246-6.126-5.016-6.126-2.771 0-5.017 2.743-5.017 6.126v17.847c0 3.383 2.246 6.126 5.017 6.126 2.77 0 5.016-2.743 5.016-6.126ZM304.353 181.317V163.47c0-3.383-2.246-6.126-5.016-6.126-2.771 0-5.017 2.743-5.017 6.126v17.847c0 3.383 2.246 6.125 5.017 6.125 2.77 0 5.016-2.742 5.016-6.125Z"
				/>
			</Svg>
		</View>
	);
}

import { useIsEligibilityGuideMatchContext } from '@mobe/features/eligibilityGuideMatch/EligibilityGuideMatchProvider';
import {
	Appearance,
	ColorSchemeName,
	Platform,
	useColorScheme as useRNColorScheme,
} from 'react-native';
import { useStorageString } from './storage/storage';

export const COLOR_SCHEME_PREFERENCE_KEY = 'colorSchemePreference';

export enum ColorSchemePreference {
	Light = 'light',
	Dark = 'dark',
	Auto = 'auto',
}

const INITIAL_DATA_NATIVE = ColorSchemePreference.Auto;
const INITIAL_DATA_WEB = ColorSchemePreference.Light;

// The useColorScheme value is always either light or dark, but the built-in
// type suggests that it can be null. This will not happen in practice, so this
// makes it a bit easier to work with.
function useDeviceColorScheme(): NonNullable<ColorSchemeName> {
	return useRNColorScheme() as NonNullable<ColorSchemeName>;
}

/**
 * Returns the user's color scheme preference of 'light', 'dark', or 'auto'.
 */
export function useColorSchemePreference(): [
	ColorSchemePreference,
	(colorSchemePreference: ColorSchemePreference) => void,
] {
	const [colorSchemePreference, setColorSchemePreference] = useStorageString(
		COLOR_SCHEME_PREFERENCE_KEY,
		Platform.OS === 'web' ? INITIAL_DATA_WEB : INITIAL_DATA_NATIVE
	);

	return [
		colorSchemePreference as ColorSchemePreference,
		(colorSchemePreference: ColorSchemePreference) => {
			setColorSchemePreference(colorSchemePreference);

			// Set appearance for native UI elements like alerts and keyboard
			if (Platform.OS !== 'web') {
				Appearance.setColorScheme(
					colorSchemePreference === ColorSchemePreference.Auto ? null : colorSchemePreference
				);
			}
		},
	];
}

/**
 * Returns 'light' or 'dark' based on the user's color scheme preference.
 * Returns the device color scheme if the user preference is set to 'auto'.
 */
export default function useColorScheme(): NonNullable<ColorSchemeName> {
	const deviceColorScheme = useDeviceColorScheme();
	const [colorSchemePreference] = useColorSchemePreference();
	const isEligibilityGuideMatchContext = useIsEligibilityGuideMatchContext();

	if (isEligibilityGuideMatchContext) {
		return 'light';
	}

	return colorSchemePreference === 'auto' ? deviceColorScheme : colorSchemePreference;
}

import { yupResolver } from '@hookform/resolvers/yup';
import {
	InputText,
	useControlledCalendarInputProps,
	useControlledInputProps,
} from '@mobe/components/input';
import InputDate from '@mobe/components/input/InputDate';
import { Text } from '@mobe/components/text';
import { emptyStringToNull } from '@mobe/utils/i18n';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { noop } from 'lodash';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import * as Yup from 'yup';
import { ITrackerForm, IUseTrackerForm } from '../../useTrackerContent';

const GOAL_INPUT_MAX_LENGTH = 8;

export default function useNumberForm({
	tracker,
	trackerEntry,
	isGoal,
	goal,
	customInputLabel,
	staticDate,
	isRequired = true,
	onKeyboardSubmit: onSubmit = noop,
}: IUseTrackerForm): ITrackerForm {
	const { t } = useTranslation();
	const { vr, constrainText } = useStyleHelpers();

	let trackerDefaultValue = null;

	const trackerSchema = Yup.number()
		.label(tracker.inputLabel)
		.nullable()
		.transform(emptyStringToNull)
		.typeError(t('forms.errors.invalidType'));

	if (trackerEntry) {
		trackerDefaultValue = trackerEntry.value;
	}

	if (isGoal && typeof goal === 'number') {
		trackerDefaultValue = goal;
	}

	const validationSchema = Yup.object({
		date: Yup.date().required(),
		tracker: isRequired ? trackerSchema.required() : trackerSchema,
	});

	const form = useForm<Yup.InferType<typeof validationSchema>>({
		mode: 'all',
		resolver: yupResolver(validationSchema),
		defaultValues: {
			date: trackerEntry
				? new Date(trackerEntry.activityDateTime)
				: staticDate || new Date(Date.now()),
			tracker: trackerDefaultValue ?? undefined,
		},
	});

	const dateInputProps = useControlledCalendarInputProps({
		name: 'date',
		control: form.control,
	});

	const trackerInputProps = useControlledInputProps({
		name: 'tracker',
		control: form.control,
	});

	function renderForm() {
		return (
			<>
				{(isGoal || customInputLabel) && (
					<Text weight="medium" size="xl" align="center" style={[constrainText(300), vr(8)]}>
						{isGoal ? tracker.goalQuestion : customInputLabel}
					</Text>
				)}
				{!isGoal && !staticDate && (
					<View style={vr(2)}>
						<InputDate
							label={t('track.entry.dateLabel')}
							popupLabel={t('track.entry.calendarTitle')}
							errorMessage={form.formState.errors.date?.message}
							{...dateInputProps}
						/>
					</View>
				)}
				<InputText
					type="number"
					label={tracker.inputLabel}
					errorMessage={form.formState.errors.tracker?.message}
					inputRight={() => (
						<Text weight="semiBold">{isGoal ? tracker.goalUnit : tracker.unit(0)}</Text>
					)}
					maxLength={isGoal ? GOAL_INPUT_MAX_LENGTH : 10}
					onSubmitEditing={onSubmit}
					{...trackerInputProps}
				/>
			</>
		);
	}

	return { form, renderForm };
}

import { yupResolver } from '@hookform/resolvers/yup';
import {
	InputText,
	useControlledCalendarInputProps,
	useControlledInputProps,
} from '@mobe/components/input';
import InputDateTime from '@mobe/components/input/InputDateTime';
import Blocks from '@mobe/components/layout/Blocks';
import { emptyStringToNull } from '@mobe/utils/i18n';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { noop } from 'lodash';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import * as Yup from 'yup';
import { ITrackerForm, IUseTrackerForm } from '../../useTrackerContent';
import { bloodPressureNumberToObject, bloodPressureObjectToNumber } from '../bloodPressureUtils';

export default function useBloodPressureFormTextInput({
	tracker,
	trackerEntry,
	isRequired = true,
	onKeyboardSubmit: onSubmit = noop,
}: IUseTrackerForm): ITrackerForm {
	const { t } = useTranslation();
	const { vr } = useStyleHelpers();

	const trackerSchema = Yup.string()
		.matches(/^(?!0\.)\d*\.\d+$/)
		.label(tracker.inputLabel)
		.transform(emptyStringToNull)
		.typeError(t('forms.errors.invalidType'));

	const validationSchema = Yup.object({
		date: Yup.date().required(),
		tracker: isRequired ? trackerSchema.required() : trackerSchema,
	});

	const form = useForm<Yup.InferType<typeof validationSchema>>({
		mode: 'all',
		resolver: yupResolver(validationSchema),
		defaultValues: {
			date: trackerEntry ? new Date(trackerEntry.activityDateTime) : new Date(Date.now()),
			tracker: String(trackerEntry?.value ?? ''),
		},
	});

	const dateInputProps = useControlledCalendarInputProps({
		name: 'date',
		control: form.control,
	});

	const trackerInputProps = useControlledInputProps({
		name: 'tracker',
		control: form.control,
	});

	const { systolic, diastolic } = bloodPressureNumberToObject(Number(trackerInputProps.value));

	function renderForm() {
		return (
			<>
				<View style={vr(2)}>
					<InputDateTime
						label={t('track.entry.dateLabel')}
						popupLabel={t('track.entry.calendarTitle')}
						errorMessage={form.formState.errors.date?.message}
						{...dateInputProps}
					/>
				</View>
				<Blocks>
					<InputText
						type="integer"
						maxLength={3}
						maxNumber={250}
						label={t('trackers.BloodPressure.systolic')}
						{...trackerInputProps}
						hasError={false}
						value={systolic ? systolic.toString() : undefined}
						onChangeText={(value) =>
							trackerInputProps.onChangeText(
								bloodPressureObjectToNumber({ systolic: Number(value), diastolic }).toString()
							)
						}
					/>
					<InputText
						type="integer"
						maxLength={3}
						maxNumber={200}
						label={t('trackers.BloodPressure.diastolic')}
						value={diastolic ? diastolic.toString() : undefined}
						onSubmitEditing={onSubmit}
						onChangeText={(value) =>
							trackerInputProps.onChangeText(
								bloodPressureObjectToNumber({ systolic, diastolic: Number(value) }).toString()
							)
						}
					/>
				</Blocks>
			</>
		);
	}

	return { form, renderForm };
}

import { INativeTrackerAdapter } from '../validicServiceUtils';

export const ACTIVITY_DATA_TYPES = [];

// Mock hook for all platforms besides iOS, there should be an iOS specific implementation of this hook at healthKit.ios.ts
export default function useHealthKitAdapter(): INativeTrackerAdapter {
	return {
		isAvailable: false,
		isConnected: false,
		async connect() {
			await new Promise((resolve) => {
				setTimeout(resolve, 500);
			});
		},
		async disconnect() {
			await new Promise((resolve) => {
				setTimeout(resolve, 500);
			});
		},
	};
}

import { useAppFeedback } from '@mobe/components/appFeedbackPopup/AppFeedbackProvider';
import useActivityToast from '@mobe/components/toast/useActivityToast';
import { assessmentScreenSequence } from '@mobe/features/onboarding/assessmentScreenSequence';
import useRemoteConfigData from '@mobe/utils/useRemoteConfigQuery';
import {
	keepPreviousData,
	useInfiniteQuery,
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query';
import { format } from 'date-fns';
import { useRefreshAllActivities } from '../activities/activitiesApiHooks';
import { ActivitiesQueryKeys } from '../activities/activitiesQueryKeys';
import { ActivityType, IGetActivitiesResponse } from '../activities/activitiesService';
import { useIsAuthenticated } from '../authentication/authApiHooks';
import {
	IPaginationRequest,
	metadataToPagination,
	paginationToMetadataRequest,
} from '../paginationUtils';
import { useGetTrackerAbbreviationFromId } from './trackApiUtils';
import {
	ITrackerType,
	TrackerAbbreviation,
	TrackerAggregatedEntriesInterval,
	addTrackerData,
	deleteTrackerData,
	getAllTrackerTypes,
	getTrackerAggregatedEntries,
	getTrackerEntries,
	getTrackerTypeSources,
	getValidicUserPlatforms,
	setTrackerFavoriteStatus,
	setTrackerGoal,
	setTrackerTypeSource,
	updateTrackerData,
} from './trackService';
import { TrackQueryKeys } from './types';

export function useTrackersQuery() {
	const isAuthenticated = useIsAuthenticated();

	return useQuery({
		queryKey: [TrackQueryKeys.AllTrackers],
		queryFn: getAllTrackerTypes,
		enabled: isAuthenticated,
		staleTime: 1000 * 30,
		// Filter out any unaccounted for tracker types for backwards compatibility with API
		select: (data) =>
			data.filter((tracker) =>
				Object.values(TrackerAbbreviation).includes(tracker.trackerAbbreviation)
			),
	});
}

interface IUseTrackerEntriesQuery extends IPaginationRequest {
	trackerTypeId: number;
	startDate: Date;
	endDate: Date;
}

export function useTrackerEntriesQuery({
	trackerTypeId,
	startDate,
	endDate,
	limit = 12,
	page = 1,
}: IUseTrackerEntriesQuery) {
	const isAuthenticated = useIsAuthenticated();
	const startDateString = format(startDate, 'yyyy-MM-dd');
	const endDateString = format(endDate, 'yyyy-MM-dd');

	return useQuery({
		queryKey: [
			TrackQueryKeys.TrackerEntries,
			trackerTypeId,
			startDateString,
			endDateString,
			limit,
			page,
		],
		queryFn: async () =>
			await getTrackerEntries(trackerTypeId, {
				startDate: startDateString,
				endDate: endDateString,
				...paginationToMetadataRequest({ limit, page }),
			}),

		enabled: isAuthenticated,
		staleTime: 1000 * 30,
		placeholderData: keepPreviousData,
		select: (data) => ({
			pagination: metadataToPagination(data.metadata),
			data: data.data,
		}),
	});
}

export function useTrackerEntriesInfiniteQuery({
	trackerTypeId,
	startDate,
	endDate,
	limit = 12,
	page = 1,
}: IUseTrackerEntriesQuery) {
	const isAuthenticated = useIsAuthenticated();
	const startDateString = format(startDate, 'yyyy-MM-dd');
	const endDateString = format(endDate, 'yyyy-MM-dd');

	return useInfiniteQuery({
		queryKey: [
			TrackQueryKeys.TrackerEntries,
			trackerTypeId,
			startDateString,
			endDateString,
			limit,
			page,
		],
		initialPageParam: page,
		queryFn: async ({ pageParam }) =>
			await getTrackerEntries(trackerTypeId, {
				startDate: startDateString,
				endDate: endDateString,
				...paginationToMetadataRequest({ limit, page: pageParam }),
			}),
		staleTime: 1000 * 30,
		placeholderData: keepPreviousData,
		enabled: isAuthenticated,
		getNextPageParam: (lastPage) => {
			const pagination = metadataToPagination(lastPage.metadata);

			if (pagination.page * pagination.limit < pagination.total) {
				return pagination.page + 1;
			}
		},
	});
}

interface IUseTrackerAggregatedEntriesQuery {
	trackerTypeId: number;
	startDate: Date;
	endDate: Date;
	interval: TrackerAggregatedEntriesInterval;
	enabled?: boolean;
}

export function useTrackerAggregatedEntriesQuery({
	trackerTypeId,
	startDate,
	endDate,
	interval,
	enabled = true,
}: IUseTrackerAggregatedEntriesQuery) {
	const isAuthenticated = useIsAuthenticated();
	const startDateString = format(startDate, 'yyyy-MM-dd');
	const endDateString = format(endDate, 'yyyy-MM-dd');

	return useQuery({
		queryKey: [
			TrackQueryKeys.TrackerAggregatedEntries,
			trackerTypeId,
			startDateString,
			endDateString,
		],
		queryFn: () =>
			getTrackerAggregatedEntries(trackerTypeId, {
				startDate: startDateString,
				endDate: endDateString,
				interval,
			}),
		staleTime: 1000 * 30,
		enabled: isAuthenticated && enabled,
		placeholderData: keepPreviousData,
	});
}

export function useTrackersFavoritesMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: setTrackerFavoriteStatus,
		// Optimistically update isFavorited so that UI responds immediately rather than waiting for success
		onMutate: async ({ trackerTypeId, isFavorite }) => {
			await queryClient.cancelQueries({ queryKey: [TrackQueryKeys.AllTrackers] });

			const previousTrackers = queryClient.getQueryData<ITrackerType[]>([
				TrackQueryKeys.AllTrackers,
			]);

			if (previousTrackers) {
				const optimisticUpdate = previousTrackers.map((tracker) =>
					tracker.trackerTypeId === trackerTypeId ? { ...tracker, isFavorite: isFavorite } : tracker
				);

				queryClient.setQueryData<ITrackerType[]>(
					[TrackQueryKeys.AllTrackers],
					() => optimisticUpdate
				);
			}

			return { previousTrackers };
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: [TrackQueryKeys.AllTrackers] });
		},
	});
}

export function useTrackersAddEntryMutation() {
	const getTrackerAbbreviationFromId = useGetTrackerAbbreviationFromId();
	const queryClient = useQueryClient();
	const refreshAllActivities = useRefreshAllActivities();
	const activityToast = useActivityToast();
	const appFeedback = useAppFeedback();

	return useMutation({
		mutationFn: addTrackerData,
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries({ queryKey: [TrackQueryKeys.AllTrackers] });
			queryClient.invalidateQueries({
				queryKey: [TrackQueryKeys.TrackerEntries, variables.trackerTypeId],
			});
			queryClient.invalidateQueries({
				queryKey: [TrackQueryKeys.TrackerAggregatedEntries, variables.trackerTypeId],
			});

			// Invalidate activities query if the tracker type matches an uncompleted activity
			const trackerAbbreviation = getTrackerAbbreviationFromId(variables.trackerTypeId);
			const newActivities = queryClient.getQueryData<IGetActivitiesResponse>([
				ActivitiesQueryKeys.AllActivities,
			])?.active;
			if (newActivities?.find((activity) => activity.activityTargetUrl === trackerAbbreviation)) {
				refreshAllActivities();
				activityToast();
				appFeedback.show();
			}

			// Invalidate activities query if the tracker type matches a question from the uncompleted assessment
			if (
				newActivities?.find((activity) => activity.type === ActivityType.Assessment) &&
				trackerAbbreviation &&
				assessmentScreenSequence
					.map((step) => step.trackerAbbreviation)
					.includes(trackerAbbreviation)
			) {
				refreshAllActivities();
			}
		},
	});
}

export function useTrackersUpdateEntryMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: updateTrackerData,
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries({ queryKey: [TrackQueryKeys.AllTrackers] });
			queryClient.invalidateQueries({
				queryKey: [TrackQueryKeys.TrackerEntries, variables.trackerEntry.trackerTypeId],
			});
			queryClient.invalidateQueries({
				queryKey: [TrackQueryKeys.TrackerAggregatedEntries, variables.trackerEntry.trackerTypeId],
			});
		},
	});
}

export function useTrackersDeleteEntryMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: deleteTrackerData,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [TrackQueryKeys.AllTrackers] });
			queryClient.invalidateQueries({ queryKey: [TrackQueryKeys.TrackerEntries] });
			queryClient.invalidateQueries({ queryKey: [TrackQueryKeys.TrackerAggregatedEntries] });
		},
	});
}

export function useTrackersGoalMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: setTrackerGoal,
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries({ queryKey: [TrackQueryKeys.AllTrackers] });
			queryClient.invalidateQueries({
				queryKey: [TrackQueryKeys.TrackerEntries, variables.trackerTypeId],
			});
			queryClient.invalidateQueries({
				queryKey: [TrackQueryKeys.TrackerAggregatedEntries, variables.trackerTypeId],
			});
		},
	});
}

export function useValidicUserPlatformsQuery() {
	const isAuthenticated = useIsAuthenticated();
	const remoteConfigData = useRemoteConfigData();
	const omittedSources = remoteConfigData.featureIntegratedTrackers.omittedSources || [];

	return useQuery({
		queryKey: [TrackQueryKeys.ValidicPlatforms],
		queryFn: getValidicUserPlatforms,
		enabled: isAuthenticated,
		// Re-fetching is handled explicitly when connection status changes
		staleTime: Infinity,
		select: (data) =>
			data.filter((platform) => !omittedSources.includes(platform.trackerSourceName)),
	});
}

export function useTrackerTypeSourcesQuery(trackerTypeId: number) {
	return useQuery({
		queryKey: [TrackQueryKeys.TrackerSources, trackerTypeId],
		queryFn: () => getTrackerTypeSources({ trackerTypeId }),
	});
}

export function useSetTrackerTypeSourceMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: setTrackerTypeSource,
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries({ queryKey: [TrackQueryKeys.AllTrackers] });
			queryClient.invalidateQueries({
				queryKey: [TrackQueryKeys.TrackerSources, variables.trackerTypeId],
			});
			queryClient.invalidateQueries({
				queryKey: [TrackQueryKeys.TrackerEntries, variables.trackerTypeId],
			});
			queryClient.invalidateQueries({
				queryKey: [TrackQueryKeys.TrackerAggregatedEntries, variables.trackerTypeId],
			});
		},
	});
}

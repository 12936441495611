import { useClientProgramReferralsQuery } from '@mobe/api/client-program-referrals/clientProgramReferralsApiHooks';
import { useAllContentQuery, useContentModulesQuery } from '@mobe/api/explore/exploreApiHooks';
import { IExploreContentCategory } from '@mobe/api/explore/exploreService';
import DeferredLoadingIndicator from '@mobe/components/deferredLoadingIndicator/deferredLoadingIndicator';
import VrArray from '@mobe/components/layout/VrArray';
import { announceForAccessibility, useAccessibilityFocus, useRestoreFocus } from '@mobe/utils/a11y';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/useStyleRules';
import useOnBottomTabPress from '@mobe/utils/useOnBottomTabPress';
import useRefetchStaleQueryOnFocusEffect from '@mobe/utils/useRefetchStaleQueryOnFocusEffect';
import { useIsFocused } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import Fuse from 'fuse.js';
import { indexOf, uniqBy } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, ScrollView, View } from 'react-native';
import BottomTabScreenTemplate from '../navigation/bottomTabs/components/BottomTabScreenTemplate';
import {
	ExploreScreenNavigationProp,
	ExploreScreenRouteProp,
} from '../navigation/bottomTabs/types';
import * as ExploreAnalyticsEvents from './analyticsEvents';
import ClientProgramReferralsModule from './components/ClientProgramReferralsModule';
import ContentCard from './components/ContentCard';
import ContentModuleCard from './components/ContentModuleCard';
import CurvedDivider from './components/CurvedDividerGraphic';
import ExploreNoResultsView from './components/ExploreNoResultsView';
import ExploreSearch from './components/ExploreSearch';
import HorizontalContentView from './components/HorizontalContentView';
import isValidSearchQuery from './isValidSearchQuery';

const MODULE_CARD_LOCATIONS = [0, 2];

interface IExploreScreenProps {
	navigation: ExploreScreenNavigationProp;
	route: ExploreScreenRouteProp;
}

export default function ExploreScreen({ navigation, route }: IExploreScreenProps) {
	const { t } = useTranslation();
	const allContentQuery = useAllContentQuery();
	const contentModulesQuery = useContentModulesQuery();
	const clientProgramReferralsQuery = useClientProgramReferralsQuery();
	const contentModulesData = contentModulesQuery.data || [];
	const { fill, background, wrapper, wrapperHorizontal, vrTop, vr } = useStyleHelpers();
	const styleRules = useStyleRules();
	const [searchValue, setSearchValue] = React.useState('');
	const isFocused = useIsFocused();
	const [refForSearchFocus, setSearchFocus] = useAccessibilityFocus(0);
	const searchScrollViewRef = React.useRef<ScrollView>(null);
	const hasProgramReferrals = Boolean(clientProgramReferralsQuery.data?.length);
	const { storeFocusTarget } = useRestoreFocus();

	React.useEffect(() => {
		if (!isFocused) {
			setSearchValue('');
		}
	}, [isFocused]);

	// Scroll to top of scroll view when search query changes
	React.useEffect(() => {
		if (!isValidSearchQuery(searchValue)) {
			return;
		}

		searchScrollViewRef.current?.scrollTo({ y: 0, animated: false });
	}, [searchValue]);

	// Reset search value when bottom tab is pressed
	useOnBottomTabPress(navigation.getParent(), () => setSearchValue(''));

	useRefetchStaleQueryOnFocusEffect([
		allContentQuery,
		contentModulesQuery,
		clientProgramReferralsQuery,
	]);

	let categories = uniqBy(allContentQuery.data, (category) => category.id).filter(
		(uniqueCategory) => uniqueCategory.items.length
	);
	const allContent = React.useMemo(
		() => uniqBy(categories?.flatMap((category) => category.items), (item) => item.sharedContentId),
		[categories]
	);
	const favoritedContent = allContent.filter((item) => item.isFavorite);
	const favoritedContentCategory: IExploreContentCategory = {
		id: t('explore.favoritesCategoryTitle'),
		items: [...favoritedContent],
	};

	// Add favorites category after first returned category from api
	categories = [
		...categories.slice(0, 1),
		favoritedContentCategory,
		...categories.slice(1, categories.length - 1),
	];

	const fuzzySearchResults = React.useMemo(
		() =>
			new Fuse(allContent, {
				keys: ['title'],
				threshold: 0.3,
				minMatchCharLength: 3,
				findAllMatches: true,
				ignoreLocation: true,
				ignoreFieldNorm: true,
			}).search(searchValue),
		[searchValue, allContent]
	);

	function handleKeyboardSearchPress() {
		if (fuzzySearchResults.length === 0) {
			announceForAccessibility(
				`${t('explore.noMatches', { searchValue })} ${t('explore.noMatchesSuggestion')}`
			);
		} else {
			announceForAccessibility(
				t('explore.searchResultsCountAccessibilityLabel', { count: fuzzySearchResults.length })
			);
			setSearchFocus();
		}
	}

	function renderAllView() {
		return (
			<FlashList
				data={categories}
				keyExtractor={(item) => item.id}
				contentContainerStyle={{
					paddingBottom: styleRules.spacing.appVerticalMargin,
				}}
				estimatedItemSize={Platform.OS === 'web' ? 638 : 280}
				ItemSeparatorComponent={() => <View style={vr(10)} />}
				renderItem={({ item: category, index }) => (
					<>
						{/* First row/section has white background and svg curved divider */}
						<View
							style={{ paddingTop: index === 0 ? styleRules.spacing.appVerticalMargin : undefined }}
						>
							{category.items.length > 0 && (
								<View>
									<HorizontalContentView
										onCardPress={(contentId, event) => {
											storeFocusTarget(event);
											navigation.navigate('EXPLORE_DETAIL_SCREEN', {
												contentId,
												contentType: 'sharedContent',
											});
										}}
										autoFocusHeading={index === 0}
										{...category}
									/>
								</View>
							)}

							{index === 0 && hasProgramReferrals ? (
								<View style={vrTop(8)}>
									<ClientProgramReferralsModule
										onProgramPress={(programId) => {
											navigation.navigate('CLIENT_PROGRAM_REFERRALS_DETAIL_SCREEN', { programId });
										}}
									/>
								</View>
							) : null}

							{index === 0 && hasProgramReferrals ? (
								<CurvedDivider style={{ marginBottom: -40 }} />
							) : null}
						</View>

						{MODULE_CARD_LOCATIONS.includes(index) &&
							indexOf(MODULE_CARD_LOCATIONS, index) < contentModulesData.length && (
								<View style={[wrapperHorizontal, vrTop(12)]}>
									<ContentModuleCard
										onPress={(contentModuleId, event) => {
											storeFocusTarget(event);
											navigation.navigate('EXPLORE_CONTENT_MODULE_SCREEN', { contentModuleId });
										}}
										contentModule={contentModulesData[indexOf(MODULE_CARD_LOCATIONS, index)]}
									/>
								</View>
							)}
					</>
				)}
			/>
		);
	}

	function renderSearchView() {
		return fuzzySearchResults.length > 0 ? (
			<ScrollView ref={searchScrollViewRef} style={[fill, background]}>
				<View style={wrapper}>
					<VrArray increment={5}>
						{fuzzySearchResults?.map(({ item }, i) => (
							<ContentCard
								ref={i === 0 ? refForSearchFocus : undefined}
								key={`${i}_${item.sharedContentId}`}
								variant="compact"
								onPress={() => {
									ExploreAnalyticsEvents.searchResultPress(item.sharedContentId);
									navigation.navigate('EXPLORE_DETAIL_SCREEN', {
										contentId: item.sharedContentId,
										contentType: 'sharedContent',
									});
								}}
								contentItem={item}
							/>
						))}
					</VrArray>
				</View>
			</ScrollView>
		) : (
			<ExploreNoResultsView searchValue={searchValue} />
		);
	}

	return (
		<BottomTabScreenTemplate screenTitle={t('explore.screenTitle')}>
			<ExploreSearch
				onChangeText={setSearchValue}
				enterKeyHint="search"
				onSubmitEditing={handleKeyboardSearchPress}
				value={searchValue}
			/>
			<DeferredLoadingIndicator
				isLoading={allContentQuery.isLoading || clientProgramReferralsQuery.isLoading}
			>
				{!isValidSearchQuery(searchValue) ? renderAllView() : renderSearchView()}
			</DeferredLoadingIndicator>
		</BottomTabScreenTemplate>
	);
}

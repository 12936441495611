interface IBloodPressure {
	systolic: number;
	diastolic: number;
}

export function bloodPressureNumberToObject(bloodPressureNumber: number): IBloodPressure {
	const [systolic, diastolic] = String(bloodPressureNumber).split('.');

	return {
		systolic: Number(systolic),
		diastolic: diastolic ? Number(diastolic.padEnd(3, '0')) : 0,
	};
}

export function bloodPressureObjectToNumber({ systolic, diastolic }: IBloodPressure) {
	return Number(`${systolic}.${String(diastolic).padStart(3, '0')}`);
}

import { yupResolver } from '@hookform/resolvers/yup';
import { useControlledCalendarInputProps } from '@mobe/components/input';
import InputDate from '@mobe/components/input/InputDate';
import LikertScaleInput from '@mobe/components/input/InputLikertScale';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import * as React from 'react';
import { useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import * as Yup from 'yup';
import { ITrackerForm, IUseTrackerForm } from '../../useTrackerContent';

interface IUseLikertScaleForm extends IUseTrackerForm {
	labelLeft?: string;
	labelCenter?: string;
	labelRight?: string;
}

export default function useLikertScaleForm({
	tracker,
	trackerEntry,
	labelLeft,
	labelCenter,
	labelRight,
	customInputLabel,
	staticDate,
	isRequired = true,
}: IUseLikertScaleForm): ITrackerForm {
	const { t } = useTranslation();
	const { vr } = useStyleHelpers();
	const trackerSchema = Yup.number().min(1).max(5).label(tracker.inputLabel);
	let trackerDefaultValue: number | undefined = undefined;

	if (trackerEntry) {
		trackerDefaultValue = trackerEntry.value;
	}

	const validationSchema = Yup.object({
		date: Yup.date().required(),
		tracker: isRequired ? trackerSchema.required() : trackerSchema,
	});

	const form = useForm<Yup.InferType<typeof validationSchema>>({
		mode: 'all',
		resolver: yupResolver(validationSchema),
		defaultValues: {
			date: trackerEntry
				? new Date(trackerEntry.activityDateTime)
				: staticDate || new Date(Date.now()),
			tracker: trackerDefaultValue,
		},
	});

	const dateInputProps = useControlledCalendarInputProps({
		name: 'date',
		control: form.control,
	});

	const likertController = useController({ name: 'tracker', control: form.control });

	function renderForm() {
		return (
			<>
				{!staticDate && (
					<View style={vr(5)}>
						<InputDate
							label={t('track.entry.dateLabel')}
							popupLabel={t('track.entry.calendarTitle')}
							errorMessage={form.formState.errors.date?.message}
							{...dateInputProps}
						/>
					</View>
				)}
				<LikertScaleInput
					label={customInputLabel || tracker.inputLabel}
					labelLeft={labelLeft || ''}
					labelRight={labelRight || ''}
					labelCenter={labelCenter}
					value={likertController.field.value}
					onPress={likertController.field.onChange}
				/>
			</>
		);
	}

	return { form, renderForm };
}
